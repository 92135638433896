import styled from "styled-components";

export const ScoreWrapper = styled.div`
  background-color: #f6f9fc;
  min-height: 95vh;
  display: flex;
  position: relative;
  gap: 3rem;
  img {
    position: absolute;
    top: 0rem;
    right: 1rem;
    height: 8rem;
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.5rem;
    gap: 0;
  }

  @media screen and (max-width: 530px) {
    img {
      position: absolute;
      top: 0rem;
      right: 1rem;
      height: 4rem;
    }
  }
`;

export const ReportButton = styled.button`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #022140;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;
  border: none;

  position: absolute;
  z-index: 10;
  right: 3rem;
  bottom: 3rem;
  cursor: pointer;
  text-align: center;

  @media screen and (max-width: 1200px) {
    bottom: -20rem;
  }

  @media screen and (max-width: 800px) {
    position: fixed;
    top: 70vh;
  }
`;

import styled from "styled-components";
import { pulseButton } from "./Animation.style";

export const ButtonWrapper = styled.div`
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  background-color: #022140;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #fff;

  position: fixed;
  z-index: 10;
  right: 4rem;
  top: 4rem;
  cursor: pointer;
  text-align: center;
  outline: medium none;
  transition: all 0.4s ease 0s;

  -webkit-animation: ${pulseButton} infinite 1.5s;
  -o-animation: ${pulseButton} infinite 1.5s;
  animation: ${pulseButton} infinite 1.5s;

  @media screen and (max-width: 541px) {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    font-size: 1rem;
    right: 4rem;
    top: 4rem;
  }

  @media screen and (max-width: 385px) {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    font-size: 1rem;
    right: 1rem;
    top: 1rem;
  }
`;

export const NextPreviousButton = styled.div`
  padding: 4rem 0 2rem 1rem;
  button {
    color: #fff;
    width: 15rem;
    cursor: pointer;
  }
  .previous {
    margin-right: 1rem;
  }
`;

import React, { useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  template1,
  template2,
  template3,
  template4,
} from "../../util/reportTemplate";
import { ReportDetail } from "../ReportDetail";
import LogoImage from "../../img/logo.png";
import { ReportWrapper } from "../../styles/Report.style";
import { PrintAsPDF } from "../PrintAsPDF";
import { ScrollTop } from "../ScrollTop";

export const Report = () => {
  const { state }: any = useLocation();
  const advancedFluency =
    state?.userPercentOwnership >= 70 && state?.userPercentImpact >= 70;
  const mediumFluencyA =
    state?.userPercentOwnership < 70 && state?.userPercentImpact >= 70;
  const mediumFluencyB =
    state?.userPercentOwnership >= 70 && state?.userPercentImpact < 70;
  const lowFluency =
    state?.userPercentOwnership < 70 && state?.userPercentImpact < 70;

  const reportRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <PrintAsPDF refsToPrint={reportRef}></PrintAsPDF>
      <ReportWrapper ref={reportRef} id="report">
        <h1>DSN Data Fluency Framework: Recommendations</h1>
        <p>
          Dear <span className="username">{state?.name}</span>,
        </p>
        <p>Thank you for taking our data fluency self-assessment.</p>
        <p>
          The impact of data-centric strategies and executions in today’s
          business environment cannot be overstated. Think of data fluency as
          the corporate skill that can get your organization from your current
          state to your dreamland. Truly, the opportunities data present are
          limitless. However, you must be a data-fluent organization to
          completely harness these opportunities. We at Data Scientists Network
          have taken it upon ourselves to make your organization a true data
          maniac so you can achieve your full potential. We bring to you the{" "}
          <span>DSN Data Fluency Framework (“DSN DFF”).</span>
        </p>
        <p>
          <span>DSN DFF</span> is a tool designed by Data Scientists Network to
          assess your organization’s ability to generate data, store them
          effectively and generate maximum value from them. The{" "}
          <span>DSN DFF</span> will help you identify the data-based areas
          within your organization that need improvement so you can be a
          completely data-fluent organization.
        </p>
        <p>
          The <span>DSN DFF</span> will help to align your values, actions, and
          data fluency so you can achieve your corporate objectives. We have
          scored you based on your answers to the questionnaire we made just for
          you and recommended exact areas you need to improve upon to be an
          organization that is not only data-driven but also derive maximum
          impact from the use of data.
        </p>
        <p>Please see below for your individual fluency assessment results.</p>

        {advancedFluency && <ReportDetail template={template1} />}
        {mediumFluencyA && <ReportDetail template={template2} />}
        {mediumFluencyB && <ReportDetail template={template3} />}
        {lowFluency && <ReportDetail template={template4} />}

        <Link to="/">
          <img
            className="logo"
            src={LogoImage}
            alt="Data Scientists Network"
            id="logo"
          />
        </Link>
      </ReportWrapper>
      <ScrollTop />
    </>
  );
};

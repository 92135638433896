import styled from "styled-components";
import BGLogo from "../img/register.webp";

export const RegisterWrapper = styled.div`
  min-height: 90vh;
  background-color: #f6f9fc;
  position: relative;
  padding: 10rem;

  .container {
    background-image: linear-gradient(
        150deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 50%,
        transparent 50%
      ),
      url(${BGLogo});
    background-size: 100%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
    border-radius: 0.8rem;
  }

  .form-wrapper {
    width: 50%;
    padding: 6rem;
  }

  form {
    h2 {
      text-align: start;
      padding: 2rem 0;
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 500;
      background-image: linear-gradient(
        to right,
        rgb(2, 33, 64),
        rgba(2, 33, 64)
      );
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      letter-spacing: 0.2rem;
    }

    .form-group:not(:last-child) {
      margin-bottom: 2rem;
    }

    .button-cmpt {
      display: flex;
      align-items: center;
    }

    input {
      font-size: 1.5rem;
      font-family: inherit;
      color: inherit;
      padding: 1.5rem 2rem;
      border-radius: 0.2rem;
      border: none;
      border-bottom: 0.3rem solid transparent;
      width: 90%;
      display: block;
      transition: all 0.3s;
    }
    input:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
      border-bottom: 0.3rem solid green;
    }

    input:focus:invalid {
      border-bottom: 0.3rem solid red;
    }
    input::-webkit-input-placeholder {
      color: #ccc;
    }

    label {
      font-size: 1.2rem;
      font-weight: 700;
      display: block;
      margin-top: 0.7rem;
      transition: all 0.3s;
    }

    input:placeholder-shown + label {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-4rem);
    }

    button {
      color: #fff;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 900px) {
    .form-wrapper {
      width: 100%;
    }
    .container {
      background-image: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0.9) 100%
        ),
        url(${BGLogo});
    }
  }

  @media screen and (max-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;

    .container {
      width: 100%;
    }

    .form-wrapper {
      padding: 2rem;
    }
    form {
      h2 {
        padding: 1rem 0;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      input {
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
      }

      button {
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;

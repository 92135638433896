import { Link } from "react-router-dom";
import { GeneralLogoWrapper } from "../styles/Logo.style";
import LogoImage from "../img/logo.png";

export const GeneralLogo = () => {
  return (
    <GeneralLogoWrapper>
      <Link to="/">
        <img src={LogoImage} alt="Data Scientists Network" />
      </Link>
    </GeneralLogoWrapper>
  );
};

import React from "react";
import { TermsWrapper } from "../../styles/Terms.style";
import { GeneralLogo } from "../Logo";
import { ScrollTop } from "../ScrollTop";

export const Terms = () => {
  return (
    <>
      <TermsWrapper>
        <GeneralLogo />
        <h1>TERMS OF SERVICE</h1>
        <p>Welcome to the Data Fluency Platform</p>
        <p>
          DSN AI Innovation Limited (“DSN”, "We," "Us," or "Our") operates the
          Data Fluency Website Application (the "Platform"). The Web Application
          is built to measure how fluently organizations communicate with data.
          The term "You" or "Your" or "User" or "Users" shall refer to any
          person or entity who views, uses, accesses, or browses any content or
          material on the Platform. These Terms of Service ("Terms") govern your
          use of Data Fluency platform web app, and other products and services
          ("Services"). As some of our Services may be software that is
          downloaded to your phone or other devices, you agree that we may
          automatically update this software and that these Terms will apply to
          such updates. Please read these Terms carefully and contact us if you
          have any questions. By using our Services, you agree to be bound by
          these Terms, including the policies referenced in these Terms.
        </p>
        <h2>SERVICE OVERVIEW</h2>
        <p>
          The Platform is for organizations interested in measuring the fluency
          of their data communication which can be achieved by answering some
          questions accurately. You will receive detailed feedback after
          submitting answers to the questions.
        </p>
        <h2>WHO MAY USE THIS SERVICE</h2>
        <p>
          Anyone representing an organization may use our Services only if it's
          in compliance with these Terms and all applicable laws. When you
          provide answers to questions, make sure they are completely accurate
          and represent the organization's current situation.
        </p>
        <h2>OUR LICENSE TO YOU</h2>
        <p>
          Subject to these Terms and our policies, we grant you a limited,
          personal, non-exclusive, non-transferable, and revocable license to
          use our Services. Using our Services does not grant you ownership of
          or any intellectual property rights in our Services or the Content you
          access.
        </p>
        <h2>DISCLAIMER AND LIMITATION OF LIABILITY</h2>
        <p>
          All information in the Service is provided "as is," with no guarantee
          of completeness, accuracy, timeliness, or the results obtained from
          the use of this information, and without warranty of any kind, express
          or implied, including, but not limited to warranties of performance
          and fitness for a particular purpose. We will not be liable to You or
          anyone else for any decision made or action taken in reliance on the
          information given by the Service or for any consequential, special, or
          similar damages.
        </p>
        <p>
          The information given by the Service is for general guidance on
          matters of interest only. DSN AI Innovation Limited takes every
          precaution to ensure that the Content on the Platform is both current
          and accurate; however, there may be errors, omissions, or inaccuracies
          in the information contained in the Service. We are not responsible
          for any such errors or omissions, or the results obtained from the use
          of the information.
        </p>
        <p>
          We also don't control what people and others do or say, and we aren't
          responsible for their (or your) actions or conduct (whether online or
          offline) or content (including unlawful or objectionable content). We
          also are not responsible for services and features offered by other
          third parties or companies, even if you access them through our
          Service.
        </p>
        <p>
          In no event shall we be liable for any loss, special, incidental,
          indirect, punitive, or consequential damages whatsoever arising out of
          or in connection with your access or use or inability to access or use
          the Platform.
        </p>
        <p>
          We further disclaim any and all liability related to your access or
          use of the services or any related content. You acknowledge and agree
          that any access to or use of the services or such Content is at your
          own risk.
        </p>
        <p>
          Our Service is provided "as is", and we can't guarantee that it will
          be safe and secure or will work perfectly all the time. TO THE EXTENT
          PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR
          IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
        </p>
        <p>
          Our responsibility for anything that happens on the Service (also
          called "liability") is limited as much as the law will allow. If there
          is an issue with our Service, we can't know what all the possible
          impacts might be. You agree that we won't be responsible ("liable")
          for any lost profits, revenues, information or data, or consequential,
          special, indirect, exemplary, punitive or incidental damages arising
          out of or related to these Terms, even if we know that they are
          possible. This includes when we delete your content, information or
          account.
        </p>
        <p>
          You acknowledge and agree that the disclaimers and the limitations of
          liability set forth in this Terms of Service reflect a reasonable and
          fair allocation of risk between You and Us. You agree that any cause
          of action related to the services must commence within one (1) year
          after the cause of action accrues. Otherwise, such cause of action is
          permanently barred.
        </p>
        <h2>THIRD PARTY LINKS</h2>
        <p>
          Third-Party links will take You out of this Website. The linked
          websites are not under the control of DSN. We are not responsible for
          the contents of any linked website or any link DSN AI Innovation
          Limited contained in a linked website or any changes or updates to
          such Websites. We provide these links to You only as a convenience,
          and the inclusion of any link does not imply endorsement by DSN AI
          Innovation Limited of the third-party Website.
        </p>
        <p>
          We will not be responsible or liable, directly or indirectly, for any
          actual or alleged damage or loss caused by or in connection with the
          use of or reliance on any such content, goods, or services available
          on or through any such site or resource.
        </p>
        <h2>USER'S CONDUCT</h2>
        <p>
          A User shall use this Platform for lawful purposes only. Accordingly,
          a User shall not post, send or transmit any material which violates or
          infringes in any way upon the rights of others or perform any act that
          is unlawful, threatening, abusive, defamatory, invasive of privacy or
          publicity rights, vulgar, obscene, profane or otherwise objectionable,
          which encourages conducts that would constitute a criminal offense,
          give rise to civil liability or otherwise violate any law.
        </p>
        <p>
          Any use of the Platform that DSN AI Innovation Limited, in its sole
          discretion, finds inappropriate and/or offensive may result in
          suspension and/or termination of a User with or without notice.
          Conduct that is inappropriate and/or offensive includes without
          limitation the following:
        </p>
        <ul>
          <li>
            Defame, abuse, harass, harm, stalk, threaten, or otherwise violate
            the legal rights (including without limitation rights of privacy and
            publicity) of others;
          </li>
          <li>
            Publish, post, upload, distribute or disseminate any profane,
            defamatory, infringing, hateful, distasteful, obscene or unlawful
            topic, name, information, materials, or Content;
          </li>
          <li>
            Use the Platform for any purpose that violates local, state,
            national, or international law, including without limitation
            wage/hour and working condition laws and regulations;
          </li>
          <li>
            Take any action that would undermine any aspect of the Platform;
          </li>
          <li>
            Attempt to gain unauthorized access to the Platform, other User
            accounts, or other devices, computer system or networks connected to
            the Platform;
          </li>
          <li>
            Impersonate another person or allow any other person or entity to
            use your user name, password, or membership;
          </li>
          <li>
            Access, download, or copy any information, Content, and/or materials
            from the Platform through artificial means (including without
            limitation spiders, scrapers, hacking devices, computer programs,
            bots, or other such means);
          </li>
          <li>
            Reproduce, duplicate, copy, sell, resell or exploit any information,
            materials, or Content on the Site; or
          </li>
          <li>
            Restrict or inhibit any other user from using and enjoying the
            Platform.
          </li>
        </ul>
        <p>
          DSN AI Innovation Limited may from time to time require you to upload
          or provide some documents and IDs to authenticate your use of the
          Platform and also confirm that You conduct and carry on Your business
          in a legal and permissible manner in accordance with the Laws of the
          Federal Republic of Nigeria.
        </p>
        <h2>COPYRIGHT AND INTELLECTUAL PROPERTY</h2>
        <p>
          All present and future rights in and to trade secrets, patents,
          copyrights, trademarks, service marks, know-how, and other proprietary
          rights of any type under the laws of any governmental authority,
          domestic or foreign, including rights in and to all applications and
          registrations relating to the services provided by this Platform (the
          "Intellectual Property Rights") shall, vest in DSN AI Innovation
          Limited.
        </p>
        <p>
          The Content on this Platform including but not limited to computer
          programs, technology, data file, techniques, algorithms, methods,
          codes, logos, marks, trade secrets, designs, text, graphics, pictures,
          audio and video files, other data or copyrightable materials or
          Content, and their selection and arrangement, are the collective work
          of DSN AI Innovation Limited.
        </p>
        <p>
          You agree to abide by all applicable copyright laws, as well as any
          additional copyright notices or restrictions contained on this
          Platform. You acknowledge that the platform is developed, compiled,
          prepared, revised, selected, and arranged by DSN AI Innovation Limited
          and its partners and constitutes their intellectual property. You
          agree to protect the proprietary rights of DSN AI Innovation Limited
          during and after the term of these Terms.
        </p>
        <p>
          In a bid to protect Your copyright as well as the copyright of other
          Users, You agree to notify Us in writing promptly upon becoming aware
          of any unauthorized access or use of this Platform by any individual
          or entity.
        </p>
        <p>
          Except as expressly authorized by Us, you agree not to reproduce,
          modify, copy or create derivative works of the Content on this
          Platform, in whole or in part, by any means without prior written
          approval from DSN AI Innovation Limited.
        </p>
        <p>
          Please note that the Service may require the use of copyrighted
          material that the copyright owner may not have specifically
          authorized. Such material is made available for fair use purpose only.
          We believe this constitutes a "fair use" of any such copyrighted
          material as provided for in the Second Schedule of the Nigerian
          Copyright Act.
        </p>
        <h2>User Content</h2>
        <p>
          The Services may contain information, text, links, graphics, photos,
          videos, audio, streams, or other materials (“Content”), including
          Content created with or submitted to the Services by the User or
          through the User’s Account (“Your Content”). We take no responsibility
          for and we do not expressly or implicitly endorse, support, or
          guarantee the completeness, truthfulness, accuracy, or reliability of
          any of Your Content.
        </p>
        <p>
          By submitting Your Content to the Services, you represent and warrant
          that you have all rights, power, and authority necessary to grant the
          rights to Your Content contained within these Terms. Because you alone
          are responsible for Your Content, you may expose yourself to liability
          if you post or share Content without all necessary rights.
        </p>
        <p>
          When Your Content is created with or submitted for the Services, you
          grant us a worldwide, royalty-free, perpetual, irrevocable,
          non-exclusive, transferable, and sublicensable license to use, copy,
          modify, adapt, prepare derivative works of, distribute, store,
          perform, and display Your Content and any name, username, voice, or
          likeness provided in connection with Your Content in all media formats
          and channels now known or later developed anywhere in the world. This
          license includes the right for us to make Your Content available for
          syndication, broadcast, distribution, or publication by other
          companies, organizations, or individuals who partner with Data
          Fluency. You also agree that we may remove metadata associated with
          Your Content, and you irrevocably waive any claims and assertions of
          moral rights or attribution with respect to Your Content.
        </p>
        <p>
          Any ideas, suggestions, and feedback about Data Fluency or our
          Services that you provide to us are entirely voluntary, and you agree
          that Data Fluency may use such ideas, suggestions, and feedback
          without compensation or obligation to you.
        </p>
        <p>
          Although we have no obligation to screen, edit, or monitor Your
          Content, we may, in our sole discretion, delete or remove Your Content
          at any time and for any reason, including for violating these Terms,
          or if you otherwise create or are likely to create liability for us.
        </p>
        <h2>REVISION AND MODIFICATION</h2>
        <p>
          We reserve the right to revise and modify these Terms at our sole
          discretion at any time. Any revisions to the Terms will be effective
          immediately upon posting by Us. Such modifications will become part of
          this Agreement and will be effective once posted by Us on the
          Platform. If a revision is material, We will notify You via electronic
          mail or a pop-up or redirection when You log in on the Platform. We
          recommend that You should review these Terms from time to time. In all
          cases, with or without notification, Your continued use of Our
          Services means that You accept any new or modified terms that we come
          up with.
        </p>
        <h2>INDEMNIFICATION</h2>
        <p>
          You agree to indemnify, defend, and hold harmless DSN AI Innovation
          Limited, its affliates, partners, subsidiaries and other
          representatives from any and all claims, liabilities, expenses, and
          damages, including reasonable attorneys' fees and costs, made by any
          third party related to: (a) your use or attempted use of the Services
          in violation of these Terms; (b) your violation of any law or rights
          of any third party including without limitation any alleged
          infringement of intellectual property or other right of any person or
          entity relating to this Platform; or (c) disputes with or between
          other Users; (d) use and/or misuse of the Platform, including without
          limitation any information, Content and/or materials thereon; (e)
          inaccurate, untimely, incomplete or misleading User information,
          including without limitation with respect to registration, profile or
          eligibility; (f) misstatements and/or misrepresentations; (g) use of
          links to third party websites, including without limitation such
          websites' availability, terms of use, privacy policy, information,
          Content, materials, advertising, products and/or services; (h) use of
          phone support services.
        </p>
        <p>
          You further agree that you will cooperate as requested by DSN AI
          Innovation Limited and its affiliates in defense of such claims. DSN
          AI Innovation Limited reserves the right, at its own expense, to
          assume the exclusive defense and control of any matter otherwise
          subject to indemnification by Users.
        </p>
        <h2>ADVERTISEMENTS AND PROMOTIONS</h2>
        <p>
          DSN AI Innovation Limited may run advertisements and promotions from
          third parties on the Platform. Your correspondence or business
          dealings with or participation in promotions of advertisers other than
          DSN AI Innovation Limited found on or through the Platform are solely
          between you and such advertiser. DSN AI Innovation Limited is not
          responsible or liable for any loss or damage of any sort incurred as
          the result of any such dealings or as the result of the presence of
          such advertisers on the Platform.
        </p>
        <h2>INJUNCTION</h2>
        <p>
          You agree that any material breach of the Terms will result in
          irreparable harm to DSN AI Innovation Limited for which damages would
          be an inadequate remedy and, therefore, in addition to its rights and
          remedies otherwise available at law, we will be entitled to equitable
          relief, including both a preliminary and permanent injunction if such
          a breach occurs.
        </p>
        <h2>GOVERNING LAW AND ARBITRATION</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the Federal Republic of Nigeria.
        </p>
        <p>
          Any controversy, claim, suit, injury, or damage arising from or in any
          way related to this Platform, its use, or the Terms shall be settled
          by binding arbitration in accordance with the Rules of the Lagos Court
          of Arbitration then in effect and before a single arbitrator chosen
          appointed in accordance with the said rules. Any such controversy,
          claim, suit, injury, or damage shall be arbitrated individually and
          shall not be consolidated in any arbitration with any controversy,
          claim, suit, injury, or damage of any other party or class action. The
          language to be used at the arbitral proceedings shall be English. The
          arbitration shall be conducted in Lagos, Nigeria, and judgment of the
          arbitration award may be entered into any court having jurisdiction
          thereof.
        </p>
        <p>
          DSN AI Innovation Limited may seek interim or preliminary relief from
          a court of competent jurisdiction in Lagos, Nigeria, necessary to
          protect its rights pending the completion of arbitration. Each party
          shall assume its own costs of arbitration.
        </p>
        <h2>TERMINATION AND SUSPENSION</h2>
        <p>
          DSN AI Innovation Limited reserves the right to suspend and/or
          terminate any user with or without notice at any time in its sole
          discretion, for any reason or no reason. You agree that if you are
          suspended and/or terminated, you will make no further use of the
          Platform after termination or during the suspension.
        </p>
        <h2>ENTIRE AGREEMENT</h2>
        <p>
          These Terms of Service, together with Our Privacy Notice, constitute
          the entire Agreement between You and Us in relation to Your use of the
          Platform and supersede all previous agreements in respect of Your use
          of this Platform.
        </p>
        <p>Any rights not expressly granted in these Terms are reserved.</p>
      </TermsWrapper>
      <ScrollTop />
    </>
  );
};

import styled from "styled-components";
import { animateProgressBar } from "./Animation.style";
import { ProgressBarWidth } from "../constant";

export const Wrapper = styled.div`
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;

  .s-progress {
    /* Values here must not be changed */
    height: 1.5rem;
    width: ${ProgressBarWidth}rem;
    background-color: #c5e5ea;
  }

  .value {
    /* Values here must not be changed */
    background-color: #2aaa9d;
    height: 1.5rem;
    animation: ${animateProgressBar} 3s ease-in;
  }

  .category {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .d-stewardship,
  .d-governance,
  .d-infrastructure,
  .o-readiness,
  .decision-making {
    padding: 1.2rem 0;
  }

  .data-impact,
  .data-ownership {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
    }

    @media screen and (max-width: 800px) {
      box-shadow: none;
    }
  }
`;

import React, { useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { ShareButtonWrapper } from "../../styles/ShareButton.style";

export const ShareButton = ({ url, title, size }: any) => {
  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    if ("clipboard" in navigator) {
      navigator.clipboard
        .readText()
        .then(async () => await navigator.clipboard.writeText(el.value));
    } else {
      return document.execCommand("copy");
    }
    document.body.removeChild(el);
    setCopied(true);
  }
  return (
    <ShareButtonWrapper>
      <FacebookShareButton
        className="facebook"
        url={url}
        quote={"I just checked my company's data fluency"}
        hashtag="#datafluency"
      >
        <FacebookIcon size={size} round={true} />
      </FacebookShareButton>
      <WhatsappShareButton title={title} url={url}>
        <WhatsappIcon size={size} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton title={title} url={url} hashtags={["datafluency"]}>
        <TwitterIcon size={size} round={true} />
      </TwitterShareButton>
      <LinkedinShareButton title={title} url={url}>
        <LinkedinIcon size={size} round={true} />
      </LinkedinShareButton>
      <button
        style={{ backgroundColor: !copied ? "#790788" : "#4FC329" }}
        onClick={copy}
      >
        {!copied ? "Copy link" : "Copied"}
      </button>
    </ShareButtonWrapper>
  );
};

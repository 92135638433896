import React from "react";
import { Wrapper } from "../../styles/ProgressBar.style";
import { CircularProgressBar } from "./CircularProgressBar";
import { TotalScore } from "../sections/TotalScore";
import { HighestWeight, ProgressBarWidth } from "../../constant";

export const ProgressBar = ({
  result,
  dataOwnershipTotalUserScore,
  dataImpactTotalUserScore,
  dataOwnershipTotalWeight,
  dataImpactTotalWeight,
}: any) => {
  return (
    <>
      <Wrapper>
        <div className="data-ownership">
          <div>
            <h4 className="category">Data Ownership</h4>
            <div className="ownership-bar">
              <div className="d-stewardship">
                <p>
                  Data stewardship {result?.dataStewardship.totalWeight} /{" "}
                  {HighestWeight * result?.dataStewardship.count}
                </p>
                <div className="s-progress">
                  <div
                    className="value"
                    style={{
                      width: `${
                        (result?.dataStewardship.totalWeight *
                          ProgressBarWidth) /
                        (HighestWeight * result?.dataStewardship.count)
                      }rem`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="d-governance">
                <p>
                  Data governance {result?.dataGovernance.totalWeight} /{" "}
                  {HighestWeight * result?.dataGovernance.count}
                </p>
                <div className="s-progress">
                  <div
                    className="value"
                    style={{
                      width: `${
                        (result?.dataGovernance.totalWeight *
                          ProgressBarWidth) /
                        (HighestWeight * result?.dataGovernance.count)
                      }rem`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="d-infrastructure">
                <p>
                  Data infrastructure {result?.dataInfrastructure.totalWeight} /{" "}
                  {HighestWeight * result?.dataInfrastructure.count}
                </p>
                <div className="s-progress">
                  <div
                    className="value"
                    style={{
                      width: `${
                        (result?.dataInfrastructure.totalWeight *
                          ProgressBarWidth) /
                        (HighestWeight * result?.dataInfrastructure.count)
                      }rem`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <TotalScore
              totalScore={dataOwnershipTotalUserScore}
              totalWeight={dataOwnershipTotalWeight}
            />
          </div>
          <CircularProgressBar
            totalUserScore={dataOwnershipTotalUserScore}
            totalWeight={dataOwnershipTotalWeight}
          />
        </div>
        <div className="data-impact">
          <div>
            <h4 className="category">Data Impact</h4>
            <div className="impact-bar">
              <div className="o-readiness">
                <p>
                  Organization readiness{" "}
                  {result?.organisationReadiness.totalWeight} /{" "}
                  {HighestWeight * result?.organisationReadiness.count}
                </p>
                <div className="s-progress">
                  <div
                    className="value"
                    style={{
                      width: `${
                        (result?.organisationReadiness.totalWeight *
                          ProgressBarWidth) /
                        (HighestWeight * result?.organisationReadiness.count)
                      }rem`,
                    }}
                  ></div>
                </div>
              </div>
              <div className="decision-making">
                <p>
                  Decision making {result?.decisionMaking.totalWeight} /{" "}
                  {HighestWeight * result?.decisionMaking.count}
                </p>
                <div className="s-progress">
                  <div
                    className="value"
                    style={{
                      width: `${
                        (result?.decisionMaking.totalWeight *
                          ProgressBarWidth) /
                        (HighestWeight * result?.decisionMaking.count)
                      }rem`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <TotalScore
              totalScore={dataImpactTotalUserScore}
              totalWeight={dataImpactTotalWeight}
            />
          </div>
          <CircularProgressBar
            totalUserScore={dataImpactTotalUserScore}
            totalWeight={dataImpactTotalWeight}
          />
        </div>
      </Wrapper>
    </>
  );
};

import React from "react";
import { InsightWrapper } from "../../styles/Insight.style";
import { useScroll } from "../../hooks/useScroll";

export const Insight = () => {
  const { ref, inView } = useScroll(0.3);
  return (
    <InsightWrapper ref={ref}>
      <h2>Insights</h2>
      <h4 className="preamble">
        The results of this exercise will be presented in the format below
      </h4>
      <div className="charts">
        <div className="progress-bar">
          <h4 className="category">Data Ownership</h4>
          <div className="data-ownership">
            <div className="ownership-bar">
              <div className={`${inView ? "d-stewardship" : "hide"}`}>
                <p>Data stewardship 15 / 20</p>
                <div className="s-progress">
                  <div className="value value-2"></div>
                </div>
              </div>
              <div className={`${inView ? "d-governance" : "hide"}`}>
                <p>Data governance 15 / 20</p>
                <div className="s-progress">
                  <div className="value value-2"></div>
                </div>
              </div>
              <div className={`${inView ? "d-infrastructure" : "hide"}`}>
                <p>Data infrastructure 15 / 20</p>
                <div className="s-progress">
                  <div className="value value-2"></div>
                </div>
              </div>
              <div className="final-score">Total Score = 45 / 60</div>
            </div>

            <svg
              className={`${inView ? "o-svg" : "hide"}`}
              width={200}
              height={200}
            >
              <circle
                className="innercircle"
                stroke="#c5e5ea"
                cx={90}
                cy={90}
                r={70}
                strokeWidth={15}
              />
              <circle
                className="progresscircle"
                stroke="#2aaa9d"
                cx={90}
                cy={90}
                r={70}
                strokeWidth={15}
                strokeDasharray={2 * Math.PI * 70}
                strokeDashoffset={120}
              />
              <text className="circle-text" x={90} y={-80}>
                75.0%
              </text>
            </svg>
          </div>
          <h4 className="category">Data Impact</h4>
          <div className="data-impact">
            <div className="impact-bar">
              <div className={`${inView ? "o-readiness" : "hide"}`}>
                <p>Organization readiness 18 / 20</p>
                <div className="s-progress">
                  <div className="value value-4"></div>
                </div>
              </div>
              <div className={`${inView ? "decision-making" : "hide"}`}>
                <p>Decision making 19 / 20</p>
                <div className="s-progress">
                  <div className="value value-5"></div>
                </div>
              </div>
              <div className="final-score">Total Score = 37 / 40</div>
            </div>
            <svg
              className={`${inView ? "i-svg" : "hide"}`}
              width={200}
              height={200}
            >
              <circle
                className="innercircle"
                stroke="#c5e5ea"
                cx={90}
                cy={90}
                r={70}
                strokeWidth={15}
              />
              <circle
                className="progresscircle"
                stroke="#2aaa9d"
                cx={90}
                cy={90}
                r={70}
                strokeWidth={15}
                strokeDasharray={2 * Math.PI * 70}
                strokeDashoffset={25}
              />
              <text className="circle-text" x={90} y={-80}>
                92.5%
              </text>
            </svg>
          </div>
        </div>
        <div className="two-by-two">
          <div className="box box-1">Data Aware</div>
          <div className={`box box-2 ${inView ? "box-2-in-view" : ""}`}>
            Data Mature
          </div>
          <div className="box box-3">Data Starter</div>
          <div className="box box-4">Data Ready</div>
        </div>
      </div>
    </InsightWrapper>
  );
};

import styled from "styled-components";

export const ReportDetailWrapper = styled.div`
  h4 {
    font-size: 1.5rem;
  }
  .result {
    background-color: #fff;

    padding: 2rem 1rem 0.3rem 1rem;
    border-left: 3px solid #011528;
    margin-bottom: 2rem;
  }
  .summary {
    font-size: 1.8rem;
  }
`;

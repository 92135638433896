import React from "react";
import { SpinnerWrapper } from "../styles/Spinner.style";

export const Spinner = () => {
  return (
    <SpinnerWrapper>
      <span />
    </SpinnerWrapper>
  );
};

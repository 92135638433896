import { Link } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { NotFoundWrapper } from "../../styles/NotFound.style";
import { Title } from "../../data-types/types";
import LogoImage from "../../img/logo.png";

export const NotFound = ({ title }: Title) => {
  useTitle("Data Fluency | Not Found");
  return (
    <NotFoundWrapper>
      <h1>
        Not found <span>:(</span>
      </h1>
      <p>{title}</p>
      <i>404</i>
      <Link to="/">
        <img src={LogoImage} alt="Data Scientists Network" />
      </Link>
    </NotFoundWrapper>
  );
};

import styled from "styled-components";

export const PrivacyWrapper = styled.div`
  position: relative;
  background-color: #f6f9fc;

  h1 {
    font-size: 3rem;
    padding: 5rem 0 3rem 0;
    letter-spacing: 0.3rem;
  }

  h2 {
    text-align: left;
    padding: 2rem 0;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    background-image: linear-gradient(
      to right,
      rgb(2, 33, 64),
      rgba(2, 33, 64)
    );
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    letter-spacing: 0.3rem;
    font-weight: 700;
  }

  p {
    padding-bottom: 2rem;
  }

  ul {
    display: inline-block;
    padding: 0 2rem;
    font-size: 1.4rem;

    li {
      padding: 0 0 2rem 0;
    }
  }
`;

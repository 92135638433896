import React from "react";
import { FluencyCriteriaWrapper } from "../../styles/FluencyCriteria.style";

export const FluencyCriteria = () => {
  return (
    <FluencyCriteriaWrapper>
      <h2>data fluency components</h2>
      <div className="all-components">
        <div className="data-ownership">
          <div className="component-box">
            <i></i>
            <h3>Data Stewardship</h3>
            <p>
              This measures how well your organization has developed an
              ecosystem for effective data accountability. If there are no
              dedicated data stewards, your efforts to use data productively
              will be frustrated. Data accountability allows for consistently
              high quality data generation and use that meet business needs.
            </p>
          </div>
          <div className="component-box">
            <i></i>
            <h3>Data Governance</h3>
            <p>
              This measures how well your company has documented and adhered to
              policies around availability, usability, integrity and security of
              business data. This is increasingly important as organizations
              have to adhere to unceasing data privacy regulations. It is
              important to clearly document who can use what data and how.
            </p>
          </div>
          <div className="component-box">
            <i></i>
            <h3>Data Infrastructure</h3>
            <p>
              This measures the availability of in-house tools necessary to turn
              data into meaningful insights that can inform business decisions.
              Investment in key data infrastructure is inevitable for a
              data-driven organization. Also, data security has to be paramount
              when considering infrastructure for your data.
            </p>
          </div>
        </div>
        <div className="data-impact">
          <div className="component-box">
            <i></i>
            <h3>Organisation Readiness</h3>
            <p>
              This measures the level of preparedness of your organization to
              use data to optimize business processes and inform strategies. For
              example, are all members of staff abreast of basic data analytics?
              You must be intentional about being ready to unlock the magic of
              data. You can be data- ready by putting processes in place to
              enable organization-wide data readiness.
            </p>
          </div>
          <div className="component-box">
            <i></i>
            <h3>Decision Making</h3>
            <p>
              This measures how much you use data and data insights to inform
              critical business decision. This is the hallmark. The use of data
              in your business strategies can make a whole lot of difference.
              Data must inform all initiatives in order to compete favourably in
              today’s business competitive landscape.
            </p>
          </div>
        </div>
      </div>
    </FluencyCriteriaWrapper>
  );
};

import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStyle } from "./styles/App.style";
import { LandingPage } from "./components/pages/LandingPage";
import { QuestionCard } from "./components/pages/QuestionCard";
import { ScoresPage } from "./components/pages/ScoresPage";
import { Footer } from "./components/sections/Footer";
import { NotFound } from "./components/pages/NotFound";
import { Register } from "./components/pages/Register";
import { Report } from "./components/pages/Report";
import { Terms } from "./components/pages/Terms";
import { Privacy } from "./components/pages/Privacy";

function App() {
  let navigate = useNavigate();

  const goToRegister = (): void => {
    navigate("/register");
  };

  const goToQuiz = (): void => {
    navigate("/questions");
  };
  return (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<LandingPage goToRegister={goToRegister} />} />
        <Route path="/register" element={<Register goToQuiz={goToQuiz} />} />
        <Route path="/questions" element={<QuestionCard />} />
        <Route path="/results/:scoreId" element={<ScoresPage />} />
        <Route path="/reports/:scoreId" element={<Report />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />

        <Route
          path="*"
          element={
            <NotFound title="Sorry, the page you are trying to view does not exist" />
          }
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import React, {
  ChangeEvent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Button } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { QuestionCardWrapper } from "../../styles/QuestionCard.styles";
import { NextPreviousButton } from "../../styles/Button.style";
import { fetchFluencyData } from "../../API";
import { QuizState, UserAnswer } from "../../data-types/types";
import { analyseResult } from "../../util/util";
import { useTitle } from "../../hooks/useTitle";
import { GeneralLogo } from "../Logo";
import { Spinner } from "../Spinner";
import { QProgressBar } from "../QProgressBar";

export const QuestionCard: React.FC = () => {
  useTitle("Data Fluency | Questions");
  let navigate = useNavigate();
  const fluencyData = useMemo(() => fetchFluencyData, []);
  const TOTAL_QUESTIONS = fluencyData.results.length;
  const [isLoading, setIsloading] = useState(false);
  const [quizOver, setQuizOver] = useState(true);
  const [qnumber, setQNumber] = useState(0);
  const [quiz, setQuiz] = useState<QuizState[]>([]);
  const [userAnswer, setUserAnswer] = useState<{ [key: string]: UserAnswer }>(
    {}
  );
  const [userScores, setUserScores] = useState({});
  const [percent, setPercent] = useState(0);

  let progress = ((qnumber + 1) * 100) / TOTAL_QUESTIONS;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let key = qnumber + 1;
    setUserAnswer({
      ...userAnswer,
      [key]: {
        category: quiz[qnumber]?.category,
        subcategory: quiz[qnumber]?.subcategory,
        answer: e.target.value,
        weight: parseInt(e.target.name, 10),
      },
    });
    setPercent(progress);
    qnumber + 1 < TOTAL_QUESTIONS && setTimeout(() => nextQuestion(), 600);
  };

  const startQuiz = useCallback((): void => {
    setIsloading(true);
    setQuizOver(false);

    try {
      setQuiz(fluencyData.results);
      setQNumber(0);
      setPercent(0);
      setIsloading(false);
      setUserAnswer({});
    } catch (error) {
      console.error(error);
    }
  }, [fluencyData.results]);

  useEffect(() => {
    startQuiz();
  }, [startQuiz]);

  const nextQuestion = useCallback((): void => {
    const nextQ = qnumber + 1;
    setQNumber(nextQ);
  }, [qnumber]);

  const previousQuestion = () => {
    let previousQ = qnumber - 1;
    if (previousQ < 0) {
      previousQ = 1;
    } else {
      setQNumber(previousQ);
    }
  };

  const submitUserAnswer = async () => {
    setIsloading((current) => !current);
    if (qnumber + 1 === Object.keys(userAnswer).length) {
      try {
        const result = analyseResult(userAnswer);
        const response = await axios.post(
          `${process.env.REACT_APP_PROXY}scores`,
          {
            data_stewardship: result.dataStewardship!.totalWeight,
            data_governance: result.dataGovernance!.totalWeight,
            data_infrastructure: result.dataInfrastructure!.totalWeight,
            organisation_readiness: result.organisationReadiness!.totalWeight,
            decision_making: result.decisionMaking!.totalWeight,
            data_stewardship_count: result.dataStewardship!.count,
            data_governance_count: result.dataGovernance!.count,
            data_infrastructure_count: result.dataInfrastructure!.count,
            organisation_readiness_count: result.organisationReadiness!.count,
            decision_making_count: result.decisionMaking!.count,
          }
        );
        setQuizOver(true);
        setPercent(0);
        setIsloading((current) => !current);
        setUserScores({ ...userScores, result });
        navigate(`/results/${response.data.id}`, { state: { result } });
      } catch (error) {
        setIsloading((current) => !current);
        console.log(error);
      }
    } else {
      setIsloading((current) => !current);
      toast.error("You need to provide answers to all the questions");
    }
  };

  return (
    <>
      <QuestionCardWrapper>
        <p className="number">
          Question: {qnumber + 1} / {TOTAL_QUESTIONS}
        </p>
        <div className="progress-bar">
          <QProgressBar percent={percent} />
        </div>
        <ul>
          <li className="category">Category: {quiz[qnumber]?.category}</li>
          <li className="subcategory">
            SubCategory: {quiz[qnumber]?.subcategory}
          </li>
        </ul>
        <span></span>
        <span></span>
        <GeneralLogo />
        <div>
          <label className="question">{quiz[qnumber]?.question}</label>
          <ul>
            {quiz[qnumber]?.answers.map((answer) => (
              <li key={answer.option} className="form-radio-list">
                <input
                  type="radio"
                  id={answer.option}
                  name={answer.weight.toString()}
                  value={answer.option}
                  className="form-radio-input"
                  checked={userAnswer[qnumber + 1]?.answer === answer.option}
                  onChange={handleChange}
                />
                <label htmlFor={answer.option} className="form-radio-label">
                  {answer.option}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <NextPreviousButton>
          {qnumber + 1 > 1 && !quizOver && !isLoading && (
            <Button
              size="large"
              icon={<ArrowLeftOutlined />}
              className="previous"
              onClick={previousQuestion}
            >
              Previous
            </Button>
          )}
          {qnumber + 1 < TOTAL_QUESTIONS && !quizOver && !isLoading && (
            <Button
              size="large"
              icon={<ArrowRightOutlined />}
              className="next"
              onClick={nextQuestion}
            >
              Next
            </Button>
          )}
          {qnumber + 1 === TOTAL_QUESTIONS && !quizOver && !isLoading && (
            <Button
              size="large"
              icon={<CheckOutlined />}
              className="submit"
              onClick={submitUserAnswer}
            >
              Submit
            </Button>
          )}
          {isLoading && <Spinner />}
        </NextPreviousButton>
        <ToastContainer />
      </QuestionCardWrapper>
    </>
  );
};

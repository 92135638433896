import { keyframes } from "styled-components";

export const moveInFromBottom = keyframes`
  0% {opacity: 0; transform: translateY(8rem)}
  50% {opacity: 1; transform: translateY(-0.5rem)}
  100% {opacity: 1; transform: translateY(0)}
`;

export const moveInFromRight = keyframes`
  0% {opacity: 0; transform: translateX(-50rem)}
  50% {opacity: 0; transform: translateX(-30rem)}
  100% {opacity: 1; transform: translateX(0)}
`;

export const animateProgressBar = keyframes`
  0% {width: 0; stroke-dashoffset: 0;}
  30% {width: 30%; stroke-dashoffset: 30%;}
  60% {width: 60%; stroke-dashoffset: 60%;}
  90% {width: 90%; stroke-dashoffset: 90%;}
  100% {width: 100%;}
`;

export const animateCircularBar = keyframes`
  0% {stroke-dashoffset: 0;}
  30% {stroke-dashoffset: 30%;}
  60% {stroke-dashoffset: 60%;}
  90% {stroke-dashoffset: 90%;}
  100% {stroke-dashoffset: 100%;}
`;

export const moveInFromDeepBottom = keyframes`
  0% {opacity: 0; transform: translateY(30rem)}
  70% {opacity: 1; transform: translateY(-0.5rem)}
  100% {opacity: 1; transform: translateY(0)}
`;

export const pulseButton = keyframes`
  0% {box-shadow: 0 0 0 0 #022140;}
  70% {box-shadow: 0 0 0 10px rgba(2, 33, 64, 0.4);}
  100% {box-shadow: 0 0 0 0 rgba(2, 33, 64, 0.4);}
`;

export const buttonBeep = keyframes`
  0% {
    transform: scale( .8 );
  }
  20% {
    transform: scale( 1 );
  }
  40% {
    transform: scale( .8 );
  }
  60% {
    transform: scale( 1.2 );
  }
  80% {
    transform: scale( .8 );
  }
  100% {
    transform: scale( .8 );
  }
`;

export const fadeAndShow = keyframes`
  0%{
    -webkit-transform: {opacity: 0};
    transform: {opacity: 0.1};
  }
  25%{
    -webkit-transform: {opacity: 0.2};
    transform: {opacity: 0.3};
  }
  50%{
    -webkit-transform: {opacity: 0.2};
    transform: {opacity: 0.6};
  }
  75%{
    -webkit-transform: {opacity: 0.2};
    transform: {opacity: 0.8};
  }
  100%{
    -webkit-transform: {opacity: 1};
    transform: {opacity: 1};
    background-color: #ebf7ee;
  }
`;

export const spinnerAnimation = keyframes`
  to {transform: rotate(360deg)}
`;

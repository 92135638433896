import styled from "styled-components";
import { animateCircularBar } from "./Animation.style";

export const CircularWrapper = styled.svg`
  transform: rotate(-90deg);
  .o-svg {
    display: block;
    margin: 1rem auto;
    max-width: 100%;
  }

  .innercircle,
  .progresscircle {
    fill: none;
  }

  .progresscircle {
    animation: ${animateCircularBar} 2s ease-in;
  }

  .circle-text {
    font-size: 2.2rem;
    text-anchor: middle;
    fill: #011529;
    font-weight: 500;
    transform: rotate(90deg);
  }
`;

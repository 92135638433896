import styled from "styled-components";

export const ShareButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 30rem;
  justify-content: space-evenly;

  button {
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    border: none;
    border-radius: 50%;
    font-size: 0.7rem;
    cursor: pointer;
    color: #fff;
    font-size: 900;
  }

  @media screen and (max-width: 800px) {
    flex-direction: row;
    margin-top: 5rem;
  }
`;

import { Link } from "react-router-dom";
import { FooterWrapper } from "../../styles/Footer.style";

export const Footer = () => {
  return (
    <FooterWrapper>
      <ul>
        <Link to="/">
          <li>Home</li>
        </Link>
        <li>
          <a
            href="https://www.datasciencenigeria.org/"
            target="_blank"
            rel="noreferrer"
          >
            Company
          </a>
        </li>
        <li>
          <a
            href="https://www.datasciencenigeria.org/contact-us/"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
        </li>
        <Link to="/terms">
          <li>Terms</li>
        </Link>
        <Link to="/privacy">
          <li>Privacy</li>
        </Link>
      </ul>
      <div>
        &copy; All right reserved{" "}
        {`${new Date().getFullYear()}. Data Scientists Network`}
      </div>
    </FooterWrapper>
  );
};

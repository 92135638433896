import React from "react";
import { AboutWrapper } from "../../styles/About.style";
import { useScroll } from "../../hooks/useScroll";

export const About = () => {
  const { ref, inView } = useScroll(0.3);
  return (
    <AboutWrapper ref={ref}>
      <h2>what you should know about this platform</h2>

      <div className="fluency-info">
        <div className="definition">
          <h3>Definition</h3>
          <p className="definition-text">
            Data fluency is the ability to comprehend data and information and
            garner insights from this information to enhance decision-making
            process. All producers and consumers of data across the organization
            have an understanding of the ability to use data to improve business
            processes.
          </p>
        </div>
        <div className="benefits">
          <h3>Benefits</h3>
          <ul className="reasons">
            <li className={`reason ${inView ? "reason1" : "hide"}`}>
              It helps your organisation understand her ability to generate data
            </li>
            <li className={`reason ${inView ? "reason2" : "hide"}`}>
              It helps identify the data-based areas within your organisation
              that needs improvement
            </li>
            <li className={`reason ${inView ? "reason3" : "hide"}`}>
              It helps align your values, actions and data fluency to achieve
              your corporate objectives
            </li>
            <li className={`reason ${inView ? "reason4" : "hide"}`}>
              It helps make your organisation a 21st century data-obsessed
              entity
            </li>
          </ul>
        </div>
      </div>
      <ul className="keypoints-container">
        <li className="keypoints">
          <h4>Purpose</h4>
          <p>
            Evaluate organisations's Data Fluency level; the ability to generate
            data, store them effectively and generate maximum value from them
          </p>
        </li>
        <li className="keypoints">
          <h4>Methodology</h4>
          <p>
            Evaluation is based on answers to series of weighted questions. A
            qualitative score is awarded after providing answers to these
            questions and recommendations made.
          </p>
        </li>
        <li className="keypoints">
          <h4>Designed For</h4>
          <p>
            This platform is designed for organisations in different sectors of
            the economy and cuts across both non-profit and for-profit
            businesses
          </p>
        </li>
      </ul>
    </AboutWrapper>
  );
};

import styled from "styled-components";
import BGImage from "../img/df1.webp";

export const HeaderWrapper = styled.header`
  height: 95vh;
  background-image: linear-gradient(
      rgba(38, 80, 119, 0.9),
      rgba(2, 33, 64, 0.9)
    ),
    url(${BGImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  h1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
  }
  h1 span {
    display: block;
  }

  .main {
    font-weight: 400;
    letter-spacing: 3.5rem;
    font-size: 6rem;
    flex-shrink: 1;
    color: #fff;
  }

  .sub {
    font-size: 1rem;
    letter-spacing: 1.8rem;
    font-weight: 200;
    color: #fff;
  }

  @media screen and (max-width: 740px) {
    .main {
      font-size: 4rem;
    }
  }

  @media screen and (max-width: 500px) {
    .main {
      letter-spacing: 1.5rem;
    }
  }

  @media screen and (max-width: 400px) {
    .main {
      letter-spacing: 1.2rem;
    }

    .sub {
      letter-spacing: 1.2rem;
    }
  }

  @media screen and (max-width: 330px) {
    .main {
      font-size: 2.5rem;
    }
    .sub {
      font-size: 0.7rem;
      letter-spacing: 1rem;
    }
  }
`;

import styled from "styled-components";

export const TwoByTwoWrapper = styled.div`
  background-color: transparent;
  width: 40%;
  height: 50rem;
  margin: 1rem auto;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "box-1 box-2"
    "box-3 box4";
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
  position: relative;
  align-self: center;

  .box {
    font-size: 1.5rem;
  }

  .box-1 {
    grid-area: "box-1";
    border: 1px solid #ccc;
  }
  .box-1::before {
    content: "High";
    margin-left: -2.2rem;
    position: absolute;
    top: 25%;
    font-size: 1rem;
  }
  .box-1::after {
    content: "Data Impact";
    position: absolute;
    top: 45%;
    left: -5.5rem;
    font-size: 1.5rem;
    transform: rotate(-90deg);
  }

  .box-2 {
    grid-area: "box-2";
    border: 1px solid #ccc;
  }
  .box-2::before {
    content: "Data Ownership";
    position: absolute;
    top: -3rem;
    left: 35%;
    font-size: 1.5rem;
  }

  .box-3 {
    grid-area: "box-3";
    border: 1px solid #ccc;
  }
  .box-3::before {
    content: "Low";
    margin-left: -2.2rem;
    position: absolute;
    font-size: 1rem;
    bottom: 25%;
  }
  .box-3::after {
    content: "Low";
    position: absolute;
    bottom: -3rem;
    left: 25%;
    font-size: 1rem;
  }
  .box-4 {
    grid-area: "box-4";
    border: 1px solid #ccc;
  }
  .box-4::before {
    content: "High";
    position: absolute;
    bottom: -3rem;
    left: 75%;
    font-size: 1rem;
  }
  .bgcolor-a {
    background-color: #fff2cc;
  }
  .bgcolor-b {
    background-color: #ebf7ee;
  }
  .bgcolor-c {
    background-color: #fee5e5;
  }
  .bgcolor-d {
    background-color: #add8e6;
  }

  @media screen and (max-width: 800px) {
    width: 50%;
    margin-bottom: 4rem;
    font-size: 1rem;
  }

  @media screen and (max-width: 700px) {
    height: 40rem;
    width: 80%;
    font-size: 1rem;

    .box-1::after {
      left: -20%;
    }
  }
`;

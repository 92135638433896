import React from "react";
import { HeaderWrapper } from "../../styles/Header.style";
import { Logo } from "../../styles/Logo.style";
import LogoImage from "../../img/logo2.png";

export const Header = () => {
  return (
    <HeaderWrapper>
      <Logo>
        <img src={LogoImage} alt="Data Scientists Network" />
      </Logo>
      <div className="header">
        <h1>
          <span className="main">Data Fluency</span>
          <span className="sub">
            an insight into companys' data communication
          </span>
        </h1>
      </div>
    </HeaderWrapper>
  );
};

import styled from "styled-components";

export const ReportWrapper = styled.div`
  background-color: #f6f9fc;
  padding: 1rem;
  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin: 4rem 0 2rem 0;
  }
  p {
    margin-bottom: 2rem;
  }
  span {
    display: inline-block;
    font-weight: 700;
  }
  .username {
    color: #415cc7;
  }
  img {
    position: absolute;
    top: 4rem;
    right: 4rem;
    height: 5rem;
    cursor: pointer;
  }

  @media screen and (max-width: 530px) {
    h1 {
      font-size: 2rem;
      width: 80%;
    }
    img {
      position: absolute;
      top: 4rem;
      right: 3rem;
      height: 4rem;
    }
  }
`;

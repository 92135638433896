import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { QuizProp } from "../../data-types/types";
import { useTitle } from "../../hooks/useTitle";
import { RegisterWrapper } from "../../styles/Register.style";
import { GeneralLogo } from "../Logo";
import useRequest from "../../hooks/useRequest";
import { Toast } from "../Toast";
import { Spinner } from "../Spinner";

export const Register: React.FC<QuizProp> = ({ goToQuiz }) => {
  useTitle("Data Fluency | Register");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const { doRequest, errors } = useRequest({
    url: `${process.env.REACT_APP_PROXY}users`,
    method: "post",
    body: { name, email },
    setItem: true,
    onSuccess: () => goToQuiz(),
  });

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsloading((current) => !current);
    await doRequest();
    if (errors) {
      toast.error("Incorrect credentials");
    }
    setIsloading((current) => !current);
  };

  return (
    <RegisterWrapper>
      <GeneralLogo />
      <div className="container">
        <div className="form-wrapper">
          <form>
            <div>
              <h2>Register</h2>
            </div>
            <div className="form-group">
              <input
                id="name"
                type="text"
                placeholder="Organisation name"
                minLength={1}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label htmlFor="name">Organisation name</label>
            </div>
            <div className="form-group">
              <input
                id="email"
                type="email"
                placeholder="Email address"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="email">Email address</label>
            </div>
            <div className="form-group button-cmpt">
              {isLoading && <Spinner />}
              <Button onClick={onSubmit} size="large" icon={<LoginOutlined />}>
                Go to questions
              </Button>
            </div>
          </form>
        </div>
      </div>
      {<Toast />}
    </RegisterWrapper>
  );
};

export const template1 = {
  fluencyType: "Data Mature - High Impact, High Ownership",
  color: "#2AAA9D",
  summary:
    "You are in a good place with Data Ownership and Data Impact. You are well on the way to becoming data-driven.",
  dataStewardship: {
    opinion: "Strong",
    statement:
      "You are ahead of the curve when it comes to Data Stewardship, and this is something you need to keep up. A developed ecosystem for effective data accountability will result in consistently highquality data for your organization.",
  },
  dataGovernance: {
    opinion: "Strong",
    statement:
      "In terms of effective governance policies, you have begun taking steps in the right direction. Policies around availability, usability, integrity, and security of business data are important to stay ahead of data privacy regulations.",
  },
  dataInfrastructure: {
    opinion: "Strong",
    statement:
      "From a data management standpoint, you would be considered strong. This means that there is the availability of in-house tools necessary to turn data into meaningful insights that can inform business decisions.",
  },
  organisationReadiness: {
    opinion: "Strong",
    statement:
      "When it comes to Organizational Readiness, you are leading. You have put processes in place to enable organization-wide data readiness. This level of preparedness will help to optimize business processes and inform strategies.",
  },
  decisionMaking: {
    opinion: "Strong",
    statement:
      "Finally, when it comes to Decision Making, you are ahead of the curve. You use data and data insights to inform critical business decisions. The use of data in your business strategies will make a whole lot of difference and will make you compete favourably in today’s business competitive landscape.",
  },
};

export const template2 = {
  fluencyType: "Data Aware – High Impact, Low Ownership",
  color: "#eed202",
  summary:
    "You're in a good place with Data Impact and can improve upon Data Ownership. You are well on the way to becoming data-driven.",
  dataStewardship: {
    opinion: "More work needed",
    statement:
      "You are behind the curve when it comes to Data Stewardship, and this is something you need to improve upon. A developed ecosystem for effective data accountability will result in consistently high-quality data for your organization.",
  },
  dataGovernance: {
    opinion: "More work needed",
    statement:
      "In terms of effective governance policies, you have begun taking steps in the right direction but you can do even more. Policies around availability, usability, integrity, and security of business data are important to stay ahead of data privacy regulations.",
  },
  dataInfrastructure: {
    opinion: "More work needed",
    statement:
      "From a data management standpoint, you would be considered average. This means if you make available effective in-house data technologies, you have the potential to turn data into meaningful insights that can truly inform business decisions and put you ahead of competition.",
  },
  organisationReadiness: {
    opinion: "Strong",
    statement:
      "When it comes to Organizational Readiness, you are leading. You have put processes in place to enable organization-wide data readiness. This level of preparedness will help to optimize business processes and inform strategies.",
  },
  decisionMaking: {
    opinion: "Strong",
    statement:
      "Finally, when it comes to Decision Making, you are ahead of the curve. You use data and data insights to inform critical business decisions. The use of data in your business strategies will make a whole lot of difference and will make you compete favourably in today’s business competitive landscape.",
  },
};

export const template3 = {
  fluencyType: "Data Ready – Low Impact, High Ownership",
  color: "#415CC7",
  summary:
    "You're in a good place with Data Ownership and can improve upon Data Impact. You are well on the way to becoming data-driven.",
  dataStewardship: {
    opinion: "Strong",
    statement:
      "You are ahead of the curve when it comes to Data Stewardship, and this is something you need to keep up. A developed ecosystem for effective data accountability will result in consistently highquality data for your organization.",
  },
  dataGovernance: {
    opinion: "Strong",
    statement:
      "In terms of effective governance policies, you have begun taking steps in the right direction. Policies around availability, usability, integrity, and security of business data are important to stay ahead of data privacy regulations.",
  },
  dataInfrastructure: {
    opinion: "Strong",
    statement:
      "From a data management standpoint, you would be considered strong. This means that there is the availability of in-house tools necessary to turn data into meaningful insights that can inform business decisions.",
  },
  organisationReadiness: {
    opinion: "More work needed",
    statement:
      "When it comes to Organizational Readiness, you are around average. You have put processes in place to enable organization-wide data readiness, but you can do even more. This level of preparedness will help to optimize business processes and inform strategies.",
  },
  decisionMaking: {
    opinion: "More work needed",
    statement:
      "Finally, when it comes to Decision Making, you are ahead of the curve. You use data and data insights to inform critical business decisions. The use of data in your business strategies will make a whole lot of difference and will make you compete favourably in today’s business competitive landscape.",
  },
};

export const template4 = {
  fluencyType: "Data Starter – Low Impact, Low Ownership",
  color: "#ee1c23",
  summary:
    "You can improve upon Data Impact and Data Ownership. You are well on the way to becoming data-driven if these improvements are executed.",
  dataStewardship: {
    opinion: "More work needed",
    statement:
      "You are behind the curve when it comes to Data Stewardship, and this is something you need to improve upon. A developed ecosystem for effective data accountability will result in consistently high-quality data for your organization.",
  },
  dataGovernance: {
    opinion: "More work needed",
    statement:
      "In terms of effective governance policies, you have begun taking steps in the right direction but you can do even more. Policies around availability, usability, integrity, and security of business data are important to stay ahead of data privacy regulations.",
  },
  dataInfrastructure: {
    opinion: "More work needed",
    statement:
      "From a data management standpoint, you would be considered average. This means if you make available effective in-house data technologies, you have the potential to turn data into meaningful insights that can truly inform business decisions and put you ahead of competition.",
  },
  organisationReadiness: {
    opinion: "More work needed",
    statement:
      "When it comes to Organizational Readiness, you are around average. You have put processes in place to enable organization-wide data readiness, but you can do even more. This level of preparedness will help to optimize business processes and inform strategies.",
  },
  decisionMaking: {
    opinion: "More work needed",
    statement:
      "Finally, when it comes to Decision Making, you are ahead of the curve. You use data and data insights to inform critical business decisions. The use of data in your business strategies will make a whole lot of difference and will make you compete favourably in today’s business competitive landscape.",
  },
};

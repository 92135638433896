import React from "react";
import { Header } from "../sections/Header";
import { FluencyCriteria } from "../sections/FluencyCriteria";
import { About } from "../sections/About";
import { Insight } from "../sections/Insight";
import { Button } from "../Button";
import { RegisterProp } from "../../data-types/types";
import { ScrollTop } from "../ScrollTop";

export const LandingPage: React.FC<RegisterProp> = ({ goToRegister }) => {
  return (
    <>
      <Button goToRegister={goToRegister} />
      <Header />
      <main>
        <About />
        <FluencyCriteria />
        <Insight />
      </main>
      <ScrollTop />
    </>
  );
};

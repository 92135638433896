import styled from "styled-components";

export const PrintWrapper = styled.div`
  position: absolute;
  button {
    display: inline-block;
    background-color: #fff;
    border: 1px solid #425cc7;
    padding: 0.7rem 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  button:hover {
    scale: 1.03;
  }
  span {
    display: inline-block;
    font-size: 1.4rem;
    color: #425cc7;
  }
  .svg-icon {
    margin-right: 0.4rem;
  }
`;

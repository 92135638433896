export const fetchFluencyData = {
  results: [
    {
      category: "Data Ownership",
      subcategory: "Data Stewardship",
      type: "multiple",
      question: "How does your organization delegate data management?",
      answers: [
        {
          option: "90% - 100%",
          weight: 5,
        },
        {
          option: "50-90%",
          weight: 4,
        },
        {
          option: "20-50%",
          weight: 3,
        },
        {
          option: "Less than 20%",
          weight: 2,
        },
        {
          option: "No staff",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Stewardship",
      type: "multiple",
      question:
        "Do you effect documentation, maintenance, and availability of data policies?",
      answers: [
        {
          option: "Only documentation",
          weight: 2,
        },
        {
          option: "Documentation and Maintenance",
          weight: 3,
        },
        {
          option: "Documentation and Availability",
          weight: 4,
        },
        {
          option: "All of the above",
          weight: 5,
        },
        {
          option: "None of the above",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Stewardship",
      type: "multiple",
      question:
        "How accessible are data-steward documentation available on a scale of 1 (not accessible) to 5 (extremely accessible)?",
      answers: [
        {
          option: "5 (extremely accessible)",
          weight: 5,
        },
        {
          option: "4",
          weight: 4,
        },
        {
          option: "3",
          weight: 3,
        },
        {
          option: "2",
          weight: 2,
        },
        {
          option: "1 (not accessible)",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Stewardship",
      type: "multiple",
      question:
        "Have data quality best practices been defined and adopted as official organizational data policies?",
      answers: [
        {
          option: "Well defined; well adopted.",
          weight: 5,
        },
        {
          option: "Well defined; somewhat adopted.",
          weight: 4,
        },
        {
          option: "Well defined; not adopted.",
          weight: 3,
        },
        {
          option: "Somewhat defined; not adopted.",
          weight: 2,
        },
        {
          option: "Not defined; not adopted",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Governance",
      type: "multiple",
      question:
        "To what degree are data governance policies formally defined, implemented and enforced?",
      answers: [
        {
          option: "Defined, implemented; effectively enforced.",
          weight: 5,
        },
        {
          option: "Defined; implemented; weakly enforced.",
          weight: 4,
        },
        {
          option: "Defined; implemented; not enforced.",
          weight: 3,
        },
        {
          option: "Defined; weakly implemented.",
          weight: 2,
        },
        {
          option: "No formal data governance policies.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Governance",
      type: "multiple",
      question:
        "What is the level of awareness of data governance policies, standards and best practices?",
      answers: [
        {
          option: "Extremely aware.",
          weight: 5,
        },
        {
          option: "Very aware.",
          weight: 4,
        },
        {
          option: "Moderately aware.",
          weight: 3,
        },
        {
          option: "Slightly aware.",
          weight: 2,
        },
        {
          option: "Not aware.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Governance",
      type: "multiple",
      question:
        "How manual or automated are efforts to retrieve data at various levels?",
      answers: [
        {
          option: "Automated.",
          weight: 5,
        },
        {
          option: "Usually automated, sometime manual.",
          weight: 4,
        },
        {
          option: "Usually manual, sometime automated.",
          weight: 3,
        },
        {
          option: "Manual.",
          weight: 2,
        },
        {
          option: "No data retrieval.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Governance",
      type: "multiple",
      question:
        "How developed is the toolset that supports data governance activities?",
      answers: [
        {
          option: "Extremely developed.",
          weight: 5,
        },
        {
          option: "Very developed.",
          weight: 4,
        },
        {
          option: "Moderately developed.",
          weight: 3,
        },
        {
          option: "Slightly developed.",
          weight: 2,
        },
        {
          option: "No toolsets.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Organizational Readiness",
      type: "multiple",
      question: "Who is the Data Owner of your organization?",
      answers: [
        {
          option: "Chief Data Officer (or similar).",
          weight: 5,
        },
        {
          option: "All line managers.",
          weight: 4,
        },
        {
          option: "All employees.",
          weight: 3,
        },
        {
          option: "Interested employees.",
          weight: 2,
        },
        {
          option: "No data owner.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Organizational Readiness",
      type: "multiple",
      question:
        "Which category of employees does your organization train when it comes to data?",
      answers: [
        {
          option: "All employees.",
          weight: 5,
        },
        {
          option: "Data-using employees.",
          weight: 4,
        },
        {
          option: "Willing employees.",
          weight: 3,
        },
        {
          option: "Randomly selected employees.",
          weight: 2,
        },
        {
          option: "None.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Organizational Readiness",
      type: "multiple",
      question:
        "Are there well-defined data roles e.g. data analysts, data scientists, ML engineers, etc.?",
      answers: [
        {
          option: "Clearly defined.",
          weight: 5,
        },
        {
          option: "Vaguely defined.",
          weight: 4,
        },
        {
          option: "External data consultants.",
          weight: 3,
        },
        {
          option: "In process of being defined.",
          weight: 2,
        },
        {
          option: "Not defined.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Organizational Readiness",
      type: "multiple",
      question: "How skilled are your staff in data operations?",
      answers: [
        {
          option: "Extremely skilled.",
          weight: 5,
        },
        {
          option: "Very skilled.",
          weight: 4,
        },
        {
          option: "Moderately skilled.",
          weight: 3,
        },
        {
          option: "Slightly skilled.",
          weight: 2,
        },
        {
          option: "Not skilled.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Infrastructure",
      type: "multiple",
      question:
        "What is your organization's general awareness to legal considerations of data use?",
      answers: [
        {
          option: "Extremely aware.",
          weight: 5,
        },
        {
          option: "Very aware.",
          weight: 4,
        },
        {
          option: "Moderately aware.",
          weight: 3,
        },
        {
          option: "Slightly aware.",
          weight: 2,
        },
        {
          option: "Not aware.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Infrastructure",
      type: "multiple",
      question:
        "Are you aware of any ethical implication of external and internal data use?",
      answers: [
        {
          option: "Extremely aware.",
          weight: 5,
        },
        {
          option: "Very aware.",
          weight: 4,
        },
        {
          option: "Moderately aware.",
          weight: 3,
        },
        {
          option: "Slightly aware.",
          weight: 2,
        },
        {
          option: "Not aware.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Infrastructure",
      type: "multiple",
      question: "How do you typically store your organization's data?",
      answers: [
        {
          option: "Databases",
          weight: 5,
        },
        {
          option: "Word document/Excel Spreadsheets",
          weight: 4,
        },
        {
          option: "PDFs.",
          weight: 3,
        },
        {
          option: "On paper.",
          weight: 2,
        },
        {
          option: "No data.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Ownership",
      subcategory: "Data Infrastructure",
      type: "multiple",
      question:
        "What data privacy policy do you have in place regarding Personally Identifiable Information (PII)?",
      answers: [
        {
          option: "Adherence to regulations.",
          weight: 5,
        },
        {
          option: "PII used based on approval.",
          weight: 4,
        },
        {
          option: "PII used discretionally.",
          weight: 3,
        },
        {
          option: "PII used freely.",
          weight: 2,
        },
        {
          option: "No privacy policy.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Decision Making",
      type: "multiple",
      question:
        "What is your organization's machine learning (ML) development and application capacity?",
      answers: [
        {
          option: "ML development; effective application.",
          weight: 5,
        },
        {
          option: "ML development; no application.",
          weight: 4,
        },
        {
          option: "ML team on the way.",
          weight: 3,
        },
        {
          option: "Emerging understanding of ML.",
          weight: 2,
        },
        {
          option: "No ML team.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Decision Making",
      type: "multiple",
      question: "How do you communicate data?",
      answers: [
        {
          option: "Internal, external, and cross-unit commmunication.",
          weight: 5,
        },
        {
          option: "Cross-unit communication.",
          weight: 4,
        },
        {
          option: "Only technical teams understand data.",
          weight: 3,
        },
        {
          option: "Only internal communication.",
          weight: 2,
        },
        {
          option: "No communication.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Decision Making",
      type: "multiple",
      question:
        "To what extent does the organization use data to organize program and initiative priorities?",
      answers: [
        {
          option: "Data informs all major initiatives.",
          weight: 5,
        },
        {
          option: "Data informs decisions in select cases.",
          weight: 4,
        },
        {
          option: "Limited data to make decisions.",
          weight: 3,
        },
        {
          option: "No initiatives.",
          weight: 2,
        },
        {
          option: "No data.",
          weight: 1,
        },
      ],
    },
    {
      category: "Data Impact",
      subcategory: "Decision Making",
      type: "multiple",
      question:
        "How effective is data in managing operations and tracking performance?",
      answers: [
        {
          option: "Extremely effective.",
          weight: 5,
        },
        {
          option: "Very effective.",
          weight: 4,
        },
        {
          option: "Moderately effective.",
          weight: 3,
        },
        {
          option: "Slightly effective.",
          weight: 2,
        },
        {
          option: "Not effective.",
          weight: 1,
        },
      ],
    },
  ],
};

import React from "react";
import { TwoByTwoWrapper } from "../../styles/TwoByTwo.style";

export const TwoByTwo = ({ userPercentOwnership, userPercentImpact }: any) => {
  return (
    <TwoByTwoWrapper>
      <div
        className={`box box-1 ${
          userPercentOwnership &&
          userPercentImpact &&
          userPercentOwnership < 70 &&
          userPercentImpact >= 70
            ? "bgcolor-a"
            : ""
        }`}
      >
        Data Aware
      </div>
      <div
        className={`box box-2 ${
          userPercentOwnership &&
          userPercentImpact &&
          userPercentOwnership >= 70 &&
          userPercentImpact >= 70
            ? "bgcolor-b"
            : ""
        }`}
      >
        Data Mature
      </div>
      <div
        className={`box box-3 ${
          userPercentOwnership &&
          userPercentImpact &&
          userPercentOwnership < 70 &&
          userPercentImpact < 70
            ? "bgcolor-c"
            : ""
        }`}
      >
        Data Starter
      </div>
      <div
        className={`box box-4 ${
          userPercentOwnership &&
          userPercentImpact &&
          userPercentOwnership >= 70 &&
          userPercentImpact < 70
            ? "bgcolor-d"
            : ""
        }`}
      >
        Data Ready
      </div>
    </TwoByTwoWrapper>
  );
};

import styled from "styled-components";

export const FluencyCriteriaWrapper = styled.section`
  background-color: rgb(38, 80, 119);
  padding-bottom: 5rem;
  text-align: center;
  h2 {
    color: #fff;
  }
  .all-components {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 20rem;
  }
  .data-ownership {
    display: flex;
    gap: 3rem;
  }
  .data-impact {
    display: flex;
    gap: 3rem;
  }
  .component-box {
    flex-wrap: wrap;
    border-radius: 0.8rem;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2.5rem;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
    border-bottom: 2.5px solid #011529;
    :hover {
      transform: translateY(-1.5rem) scale(1.03);
    }
  }

  p {
    font-size: 1.25rem;
    letter-spacing: 0.15rem;
  }
  h3 {
    padding: 1rem 0;
    font-weight: 200;
  }

  @media screen and (max-width: 800px) {
    .data-ownership,
    .data-impact {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 460px) {
    .all-components {
      padding: 0 10rem;
    }
  }

  @media screen and (max-width: 709px) {
    .component-box {
      width: 32rem;
    }
  }
`;

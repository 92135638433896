import { UserAnswer } from "../data-types/types";
export function analyseResult(userAnswer: { [key: string]: UserAnswer }) {
  let dataStewardship = { totalWeight: 0, count: 0 };
  let dataGovernance = { totalWeight: 0, count: 0 };
  let dataInfrastructure = { totalWeight: 0, count: 0 };
  let organisationReadiness = { totalWeight: 0, count: 0 };
  let decisionMaking = { totalWeight: 0, count: 0 };

  for (const key in userAnswer) {
    if (userAnswer[key].subcategory === "Data Stewardship") {
      dataStewardship.totalWeight += userAnswer[key].weight;
      dataStewardship.count += 1;
    } else if (userAnswer[key].subcategory === "Data Governance") {
      dataGovernance.totalWeight += userAnswer[key].weight;
      dataGovernance.count += 1;
    } else if (userAnswer[key].subcategory === "Data Infrastructure") {
      dataInfrastructure.totalWeight += userAnswer[key].weight;
      dataInfrastructure.count += 1;
    } else if (userAnswer[key].subcategory === "Organizational Readiness") {
      organisationReadiness.totalWeight += userAnswer[key].weight;
      organisationReadiness.count += 1;
    } else if (userAnswer[key].subcategory === "Decision Making") {
      decisionMaking.totalWeight += userAnswer[key].weight;
      decisionMaking.count += 1;
    } else {
      return {};
    }
  }
  return {
    dataStewardship,
    dataGovernance,
    dataInfrastructure,
    organisationReadiness,
    decisionMaking,
  };
}

import styled from "styled-components";
import { moveInFromBottom } from "./Animation.style";

export const AboutWrapper = styled.section`
  background-color: #f6f9fc;
  padding: 0 2rem 5rem 2rem;

  h3 {
    padding: 2.5rem 0;
  }
  .fluency-info {
    padding: 0 2rem 3.5rem 2rem;
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    border-bottom: 1px solid #ccc;
    padding-bottom: 5rem;
    margin-bottom: 3rem;
  }

  .definition {
    width: 60%;
  }
  .definition-text {
    text-align: justify;
    text-justify: auto;
    word-break: break-all;
  }
  .benefits {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    width: 100%;
  }

  li {
    list-style-type: none;
  }
  .reason {
    letter-spacing: 0.15rem;
    padding: 2.5rem 1rem;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    border-bottom: 2px solid #011529;
    transition: transform 0.3s;
    :hover {
      transform: translateY(-0.4rem) scale(1.03);
    }
  }
  .hide {
    opacity: 0;
  }

  .reason1 {
    -webkit-animation: ${moveInFromBottom} 1s ease-in;
    -o-animation: ${moveInFromBottom} 1s ease-in;
    animation: ${moveInFromBottom} 1s ease-in;
  }

  .reason2 {
    -webkit-animation: ${moveInFromBottom} 2s ease-in;
    -o-animation: ${moveInFromBottom} 2s ease-in;
    animation: ${moveInFromBottom} 2s ease-in;
  }

  .reason3 {
    -webkit-animation: ${moveInFromBottom} 3s ease-in;
    -o-animation: ${moveInFromBottom} 3s ease-in;
    animation: ${moveInFromBottom} 3s;
  }

  .reason4 {
    -webkit-animation: ${moveInFromBottom} 4s ease-in;
    -o-animation: ${moveInFromBottom} 4s ease-in;
    animation: ${moveInFromBottom} 4s;
  }

  .keypoints-container {
    display: flex;
    counter-reset: list-items;
    gap: 3rem;
  }

  .keypoints {
    text-align: center;
    flex-basis: 100%;
    position: relative;
    font-size: 1.5rem;
    :hover {
      border-radius: 0.8rem;
      background-color: #fff;
      box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
      border-bottom: 2px solid #011529;
      transition: transform 0.3s;
      transform: translateY(-0.4rem) scale(1.03);
    }
    padding: 1rem 0 1rem 1rem;
  }

  .keypoints::before {
    counter-increment: list-items;
    content: counter(list-items);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1.2rem;
    background-color: #fff;
    border: 0.2rem solid black;
    font-weight: 200;
    margin: auto;
  }
  h4 {
    padding: 1.5rem 0;
  }

  @media screen and (max-width: 574px) {
    .definition {
      width: 100%;
    }
    .fluency-info {
      flex-direction: column;
    }
    .keypoints-container {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 500px) {
    .reason1,
    .reason2,
    .reason3,
    .reason4 {
      -webkit-animation: none;
      -o-animation: none;
      animation: none;
    }
  }
`;

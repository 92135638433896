import React from "react";
import { FaDownload } from "react-icons/fa";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import { PrintWrapper } from "../styles/PrintAsPDF.style";

export const PrintAsPDF = ({ refsToPrint }: any) => {
  const downloadPdfDocument = () => {
    html2canvas(refsToPrint!.current, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", canvas.width);
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: false,
      });
      const width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width - 10, height - 10);
      pdf.save("report.pdf");
    });
  };
  return (
    <PrintWrapper id="download">
      <button onClick={downloadPdfDocument}>
        {" "}
        <span className="svg-icon">
          <FaDownload
            fill="#425cc7"
            stroke="#425cc7"
            color="#425cc7"
            height="1.4rem"
          />
        </span>{" "}
        <span>Save as PDF</span>
      </button>
    </PrintWrapper>
  );
};

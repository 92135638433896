import styled from "styled-components";
import { spinnerAnimation } from "./Animation.style";

export const SpinnerWrapper = styled.div`
  span {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: middle;
    border: 0.5rem solid #022140;
    border-left-color: transparent;
    animation: ${spinnerAnimation} 1s linear infinite;
  }
`;

import styled from "styled-components";

export const QuestionCardWrapper = styled.div`
  background-color: #f6f9fc;
  position: relative;
  min-height: 95vh;
  img {
    position: absolute;
    top: 0rem;
    right: 1rem;
    height: 8rem;
    cursor: pointer;
  }
  ul {
    list-style: none;
    overflow-wrap: break-word;
    hyphens: auto;
    cursor: pointer;
  }
  .number {
    padding: 2rem 0 2rem 1rem;
  }

  .question {
    display: inline-block;
    padding: 1rem;
    font-size: 2.5rem;
    overflow-wrap: break-word;
  }

  .form-radio-input {
    display: none;
  }

  .form-radio-list {
    display: flex;
    align-items: flex-start;
    background-color: #fff;

    border-radius: 8px;
    margin: 0 1rem 2rem 1rem;
  }

  .form-radio-label::before {
    content: "";
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: inline-block;
    box-shadow: inset 0 0 0 4px #fff;
    padding: 1.5rem;
    margin-right: 1rem;
    background-color: #fff;
    border: 4px solid rgba(7, 30, 45, 0.2);
  }

  .form-radio-label {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    border-left: 5px solid #011528;
    border-radius: 8px;
    width: 100%;
    padding: 1.5rem;
  }

  .form-radio-list input:checked + .form-radio-label {
    background-color: #dffbed;
    border: 3px solid #44bb82;
    border-radius: 8px;
  }

  .form-radio-list input:checked + .form-radio-label::before {
    background-color: #44bb82;
    border: 3px solid #44bb82;
  }

  .category,
  .subcategory {
    margin-right: 5rem;
    color: rgb(147, 147, 147);
    padding: 0 1rem;
    font-weight: 300;
  }

  .progress-bar {
    position: absolute;
    top: -1rem;
    width: 100%;
  }

  @media screen and (max-width: 530px) {
    img {
      position: absolute;
      top: 0rem;
      right: 1rem;
      height: 4rem;
    }
  }
`;

import { useEffect, useState } from "react";
import { useLocation, Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReportButton, ScoreWrapper } from "../../styles/Scores.style";
import { ProgressBar } from "../visuals/ProgressBar";
import { TwoByTwo } from "../visuals/TwoByTwo";
import { HighestWeight } from "../../constant";
import { useTitle } from "../../hooks/useTitle";
import { NotFound } from "./NotFound";
import LogoImage from "../../img/logo.png";
import useRequest from "../../hooks/useRequest";
import { ScoreProp } from "../../data-types/types";
import { ShareButton } from "../visuals/ShareButton";

export const ScoresPage = () => {
  useTitle("Data Fluency | Scores");
  const { state }: any = useLocation();
  let navigate = useNavigate();
  const { scoreId } = useParams();
  const absoluteURL = window.location.href;
  const [score, setScore] = useState<ScoreProp | undefined>(undefined);
  const userId = localStorage.getItem("id");

  const { doRequest, errors } = useRequest({
    url: `${process.env.REACT_APP_PROXY}scores/${scoreId}`,
    method: "get",
    body: {},
    setItem: false,
    onSuccess: () => {},
  });

  useEffect(() => {
    const getScores = async () => {
      const response = await doRequest();
      setScore(response);
      if (errors) {
        toast(errors);
      }
    };
    if (!score) {
      getScores();
    }
  }, [score, errors, doRequest]);

  const result = {
    dataStewardship: {
      count: score?.data_stewardship_count!,
      totalWeight: score?.data_stewardship!,
    },
    dataGovernance: {
      count: score?.data_governance_count!,
      totalWeight: score?.data_governance!,
    },
    dataInfrastructure: {
      count: score?.data_infrastructure_count!,
      totalWeight: score?.data_infrastructure!,
    },
    organisationReadiness: {
      count: score?.organisation_readiness_count!,
      totalWeight: score?.organisation_readiness!,
    },
    decisionMaking: {
      count: score?.decision_making_count!,
      totalWeight: score?.decision_making!,
    },
  };

  const dataOwnershipTotalUserScore: number =
    state?.result?.dataStewardship.totalWeight +
      state?.result?.dataGovernance.totalWeight +
      state?.result?.dataInfrastructure.totalWeight ||
    score?.data_stewardship! +
      score?.data_governance! +
      score?.data_infrastructure!;

  const dataImpactTotalUserScore: number =
    state?.result?.organisationReadiness.totalWeight +
      state?.result?.decisionMaking.totalWeight ||
    score?.organisation_readiness! + score?.decision_making!;

  const dataOwnershipTotalWeight =
    HighestWeight *
      (state?.result?.dataStewardship.count +
        state?.result?.dataGovernance.count +
        state?.result?.dataInfrastructure.count) ||
    HighestWeight *
      (score?.data_stewardship_count! +
        score?.data_governance_count! +
        score?.data_infrastructure_count!);

  const dataImpactTotalWeight =
    HighestWeight *
      (state?.result?.organisationReadiness.count +
        state?.result?.decisionMaking.count) ||
    HighestWeight *
      (score?.organisation_readiness_count! + score?.decision_making_count!);

  const userPercentOwnership = (
    (dataOwnershipTotalUserScore / dataOwnershipTotalWeight) *
    100
  ).toPrecision(3);

  const userPercentImpact = (
    (dataImpactTotalUserScore / dataImpactTotalWeight) *
    100
  ).toPrecision(3);

  return (
    <>
      <ScoreWrapper id="scorespage">
        {state?.result || result ? (
          <>
            <ProgressBar
              result={state?.result || result}
              dataOwnershipTotalUserScore={dataOwnershipTotalUserScore}
              dataImpactTotalUserScore={dataImpactTotalUserScore}
              dataOwnershipTotalWeight={dataOwnershipTotalWeight}
              dataImpactTotalWeight={dataImpactTotalWeight}
            />
            <TwoByTwo
              userPercentOwnership={userPercentOwnership}
              userPercentImpact={userPercentImpact}
            />
            <Link to="/">
              <img src={LogoImage} alt="Data Scientists Network" />
            </Link>
            {score?.user?.id === userId && (
              <ShareButton
                url={absoluteURL}
                title={"I just checked my company's data fluency"}
                size={30}
              />
            )}
          </>
        ) : (
          <NotFound title="You need to answer the questions before viewing this page" />
        )}
      </ScoreWrapper>
      {score?.user?.id === userId && (
        <ReportButton
          onClick={() =>
            navigate(`/reports/${scoreId}`, {
              state: {
                userPercentOwnership,
                userPercentImpact,
                name: score!.user!.name,
              },
            })
          }
        >
          View report
        </ReportButton>
      )}
    </>
  );
};

import React from "react";
import { Template } from "../data-types/types";
import { ReportDetailWrapper } from "../styles/ReportDetail.style";

export const ReportDetail = ({ template }: Template) => {
  return (
    <ReportDetailWrapper>
      <h3 style={{ color: template.color }}>{template.fluencyType}</h3>
      <div>
        <h4 className="summary">Summary:</h4>
        <p>{template.summary}</p>
      </div>
      <div className="result">
        <h4>
          Data Stewardship -{" "}
          <span style={{ color: template.color }}>
            {template.dataStewardship.opinion}
          </span>
        </h4>
        <p>{template.dataStewardship.statement}</p>
      </div>
      <div className="result">
        <h4>
          Data Governance -{" "}
          <span style={{ color: template.color }}>
            {template.dataGovernance.opinion}
          </span>
        </h4>
        <p>{template.dataGovernance.statement}</p>
      </div>
      <div className="result">
        <h4>
          Data Infrastructure -{" "}
          <span style={{ color: template.color }}>
            {template.dataInfrastructure.opinion}
          </span>
        </h4>
        <p>{template.dataInfrastructure.statement}</p>
      </div>
      <div className="result">
        <h4>
          Organisation Readiness -{" "}
          <span style={{ color: template.color }}>
            {template.organisationReadiness.opinion}
          </span>
        </h4>
        <p>{template.organisationReadiness.statement}</p>
      </div>
      <div className="result">
        <h4>
          Decision Making -{" "}
          <span style={{ color: template.color }}>
            {template.decisionMaking.opinion}
          </span>
        </h4>
        <p>{template.decisionMaking.statement}</p>
      </div>
    </ReportDetailWrapper>
  );
};

export default ReportDetail;

import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background-color: rgb(2, 33, 64);
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 6rem 2rem 0 2rem;

  font-size: 1.5rem;

  ul {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    padding: 0 15rem;
    transition: all 0.3s;
  }
  li {
    list-style-type: none;
    color: #fff;
    cursor: pointer;
    :hover {
      transform: scale(1.03);
      font-weight: 700;
      font-size: 1.52rem;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  div {
    text-align: center;
    padding: 6rem 0 2rem 0;
    color: #fff;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.2rem;

    ul {
      padding: 0;
    }
  }
`;

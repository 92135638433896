import styled from "styled-components";
import {
  moveInFromRight,
  moveInFromDeepBottom,
  fadeAndShow,
} from "./Animation.style";

export const InsightWrapper = styled.section`
  background-color: #f6f9fc;
  padding-bottom: 5rem;

  .preamble {
    font-size: 2rem;
    padding-bottom: 2.5rem;
    font-weight: 400;
    padding: 0 4rem 4rem;
  }

  .charts {
    display: flex;
    gap: 3rem;
    padding: 0 4rem;
  }

  .category {
    font-size: 2rem;
  }

  .data-ownership,
  .data-impact {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .o-svg,
  .i-svg {
    display: block;
    margin: 1rem auto;
    max-width: 100%;
    transform: rotate(-90deg);

    align-self: stretch;
  }

  .innercircle,
  .progresscircle {
    fill: none;
  }

  .circle-text {
    font-size: 2.2rem;
    text-anchor: middle;
    fill: #011529;
    font-weight: 500;
    transform: rotate(90deg);
  }

  .progress-bar {
    width: 100%;
  }
  .s-progress {
    height: 1rem;
    width: 30rem;
    background-color: #c5e5ea;
  }

  .value {
    background-color: #2aaa9d;
    height: 1rem;
  }
  .value-1 {
    width: 12rem;
  }
  .value-2 {
    width: 22.5rem;
  }
  .value-3 {
    width: 15rem;
  }
  .value-4 {
    width: 27rem;
  }
  .value-5 {
    width: 28.5rem;
  }

  .d-stewardship,
  .d-governance,
  .d-infrastructure,
  .o-readiness,
  .decision-making {
    padding: 1.2rem 0;
    animation: ${moveInFromRight} 2s;
  }

  .o-svg {
    animation: ${moveInFromDeepBottom} 2s;
    animation-delay: 0;
  }

  .i-svg {
    animation: ${moveInFromDeepBottom} 2s;
    animation-delay: 0;
  }

  .two-by-two {
    background-color: transparent;
    width: 60%;
    margin: 1rem auto;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "box-1 box-2"
      "box-3 box4";
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.3);
    position: relative;
  }

  .box {
    font-size: 1.5rem;
  }

  .box-1 {
    grid-area: "box-1";
    border: 1px solid #ccc;
  }
  .box-2 {
    grid-area: "box-2";
    border: 1px solid #ccc;
  }
  .box-2-in-view {
    animation: ${fadeAndShow} 1s infinite ease-in-out;
  }
  .box-3 {
    grid-area: "box-3";
    border: 1px solid #ccc;
  }
  .box-4 {
    grid-area: "box-4";
    border: 1px solid #ccc;
  }

  .box-1::before {
    content: "High";
    margin-left: -4rem;
    position: absolute;
    top: 25%;
  }

  .box-1::after {
    content: "Data Impact";
    position: absolute;
    top: 45%;
    left: -10rem;
    font-size: 2rem;
    transform: rotate(-90deg);
  }

  .box-2::before {
    content: "Data Ownership";
    position: absolute;
    top: -4rem;
    left: 35%;
    font-size: 2rem;
  }

  .box-3::before {
    content: "Low";
    margin-left: -4rem;
    position: absolute;
    bottom: 25%;
  }

  .box-3::after {
    content: "Low";
    position: absolute;
    bottom: -3rem;
    left: 25%;
  }

  .box-4::before {
    content: "High";
    position: absolute;
    bottom: -3rem;
    left: 75%;
  }
  .hide {
    opacity: 0;
  }

  .final-score {
    font-size: 2rem;
    color: inherit;
    font-weight: 900;
    padding: 1rem 0;
  }

  @media screen and (max-width: 992px) {
    .charts {
      flex-direction: column;
    }
    .two-by-two {
      height: 50rem;
      width: 50%;
    }

    .data-ownership,
    .data-impact {
      justify-content: space-between;
    }

    .o-svg,
    .i-svg {
      margin: 0;
    }
  }

  @media screen and (max-width: 700px) {
    .two-by-two {
      height: 40rem;
      width: 80%;
    }

    .data-ownership,
    .data-impact {
      flex-direction: column;
    }
    .o-svg,
    .i-svg {
      margin: auto;
    }
    .box-2::before {
      /* top: -5%; */
      left: 35%;
      font-size: 1.5rem;
      top: -3rem;
    }
    .box-1::after {
      top: 45%;
      left: -7rem;
      font-size: 1.5rem;
      transform: rotate(-90deg);
    }

    .d-stewardship,
    .d-governance,
    .d-infrastructure,
    .o-readiness,
    .decision-making {
      padding: 1.2rem 0;
    }
  }

  @media screen and (max-width: 540px) {
    .two-by-two {
      height: 40rem;
      width: 80%;
    }
  }

  @media screen and (max-width: 500px) {
    .preamble {
      font-size: 1.5rem;
      padding: 0 0 2.5rem 0.6rem;
    }
    .charts {
      padding: 0.6rem;
    }

    .d-stewardship,
    .d-governance,
    .d-infrastructure,
    .o-readiness,
    .decision-making {
      animation: none;
    }
    .o-svg {
      animation: none;
    }
    .i-svg {
      animation: none;
    }
  }
`;

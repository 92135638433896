import React from "react";
import { CircularWrapper } from "../../styles/CircularProgressBar.style";

export const CircularProgressBar = ({ totalUserScore, totalWeight }: any) => {
  const total = (totalUserScore * 100) / totalWeight;
  return (
    <>
      {totalUserScore && totalWeight && (
        <CircularWrapper className="o-svg" width={200} height={200}>
          <circle
            className="innercircle"
            stroke="#c5e5ea"
            cx={90}
            cy={90}
            r={70}
            strokeWidth={15}
          />
          <circle
            className="progresscircle"
            stroke="#2aaa9d"
            cx={90}
            cy={90}
            r={70}
            strokeWidth={15}
            strokeDasharray={2 * Math.PI * 70}
            strokeDashoffset={
              2 * Math.PI * 70 -
              (2 * Math.PI * 70 * totalUserScore) / totalWeight
            }
          />
          <text className="circle-text" x={90} y={-80}>
            {`${total.toPrecision(3)}%`}
          </text>
        </CircularWrapper>
      )}
    </>
  );
};

import React from "react";
import { Progress } from "antd";

type Percent = {
  percent: number;
};

export const QProgressBar = ({ percent }: Percent) => (
  <>
    <Progress
      percent={percent}
      status="active"
      strokeColor={"#44bb82"}
      trailColor={"#eee"}
    />
  </>
);

import React from "react";
import { PrivacyWrapper } from "../../styles/Privacy.style";
import { GeneralLogo } from "../Logo";
import { ScrollTop } from "../ScrollTop";

export const Privacy = () => {
  return (
    <>
      <PrivacyWrapper>
        <GeneralLogo />
        <h1>PRIVACY NOTICE</h1>
        <p>Welcome to Data fluency Platform Privacy Policy Notice("Policy")</p>
        <p>
          It is our commitment to protect the privacy of all our website
          visitors, both the Users and other persons who access our services.
          Please read the following Privacy Policy to explain to you how we use
          and protect your details.
        </p>
        <p>
          The purpose of this Privacy Notice is to inform you of our Policies
          and describe how We collect, uses, stores, and shares information
          about you through our online interfaces (e.g., websites and mobile
          applications) owned and controlled by us (collectively referred to
          herein as the "Platform").
        </p>
        <p>
          Please note that DSN AI Innovation Limited ("We," "Us," and "Our") is
          the data controller of the personal information we collect about you
          (i.e., the entity that determines the means and purposes of
          collecting, using, and disclosing the personal information). We
          reserve the right to modify this Policy without any notification on
          our Website. Providing your information is voluntary, and your use of
          the Platform after the effective date of this Policy constitutes your
          acceptance of this Privacy Policy. Please read this Privacy Policy
          carefully to understand what we do. If you do not understand any
          aspects of our Privacy Notice, please feel free to contact us at
          www.learnathome.ng. Note also that Your use of our Platform is also
          governed by our Terms of Use.
        </p>
        <h2>WHAT INFORMATION DO WE COLLECT?</h2>
        <p>
          When you access the Platform, we collect Personal Data provided by you
          or via a third party representing your organization. We may use the
          Personal Data that you provide to respond to your questions, provide
          you the specific Content Offering and/or Services you select, send you
          updates about Content and services on the Platform, and send you
          e-mail messages about the Platform maintenance or updates.
        </p>
        <p>
          The following information is what We collect when you access the
          Platform;
        </p>
        <ul>
          <li>
            Profile data, such as your organization name, e-mail address, and
            phone number;
          </li>
          <li>Marketing data, such as users' feedback;</li>
        </ul>
        <h2>Cookies</h2>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. These are sent to your browser from
          the websites that you visit and are stored on your device's internal
          memory.
        </p>
        <p>
          This Service does not use these “cookies” explicitly. However, the
          platform may use third party code and libraries that use “cookies” to
          collect information and improve their services. You have the option to
          either accept or refuse these cookies and know when a cookie is being
          sent to your device. If you choose to refuse our cookies, you may not
          be able to use some portions of this Service.
        </p>
        <h2>HOW WE USE YOUR DATA</h2>
        <p>
          We will only use your data for the purpose for which We collected it.
          Below are some ways we use Your Personal Data:
        </p>
        <p>
          IDENTIFY AND PROVIDE THE RIGHT SERVICE/CONTENT FOR YOU: We use
          information relating to your use of the Platform to build higher
          quality, more useful Services by performing statistical analyses of
          our users' collective characteristics and behavior and by measuring
          demographics and interests regarding specific areas of the Platform.
          This enables us to create the Content most relevant to you and to
          improve Our Services generally;
        </p>
        <ul>
          <li>
            MANAGE YOUR RELATIONSHIP WITH US: We use Personal Data that you
            provide to us in order to allow you to register as a new user,
            access and use the Platform, and in order to provide any
            information, products, or services that you request from us.
          </li>
          <li>
            TECHNICAL SUPPORT AND SECURITY: We use Personal Data to ensure the
            security of the Platform and our Services. We use Personal Data to
            manage risk or detect, prevent, and/or remediate fraud or other
            potentially prohibited or illegal. We may use Personal Data to
            provide technical support to you, where required.
          </li>
          <li>
            COMMUNICATION UPDATES: We use Personal Data collected when you
            sign-up for our various SMS, e-mail, or any update services to send
            you the messages in connection with the Platform or Content
            Offerings. We may also archive this information and/or use it for
            future communications with you, where we are legally entitled to do
            so. When you send us an e-mail message or otherwise contact us, we
            may use the information provided by you to respond to your
            communication and/or as described in this Privacy Notice. We may
            also archive this information and/or use it for future
            communications with you where we are legally entitled to do so.
            Where we send you e-mails, we may track how you interact with these
            e-mails (such as when you open an e-mail or click on a link inside
            an e-mail). We use this information to optimize and better tailor
            our communications to you.
          </li>
          <li>
            IDENTITY VERIFICATION: For Services that require identity
            verification, we may use the Personal Data that we collect to verify
            your identity and authenticate that submissions made on the Platform
            were made by you. This Service may be provided through a third-party
            identity verification vendor. Your photo identification document
            will be deleted after successful verification of your profile
            information.
          </li>
          <li>
            RESEARCH: We may share general data (including quiz and forum
            discussions), information about your activity on the Platform, and
            demographic data from surveys operated by us with our partners so
            that they may use the Data for research purposes relating to online
            education.
          </li>
          <li>
            PROMOTION AND ADVERTISEMENT: We may use your information for
            displaying third-party advertisements to make our Services available
            for free and cross-promoting our Services.
          </li>
          <li>
            GOVERNMENT AUTHORITIES; LEGAL RIGHTS AND ACTIONS: We may share your
            data with various government authorities in response to subpoenas,
            court orders, or other legal processes; to establish or exercise our
            legal rights or to protect our property; to defend against legal
            claims; or as otherwise required by law. In such cases, we reserve
            the right to raise or waive any legal objection or right available
            to us. We also may share your Personal Data when we believe it is
            appropriate to investigate, prevent, or take action regarding
            illegal or suspected illegal activities; to protect and defend the
            rights, property, or safety of DSN AI Innovation Limited, the
            Platform, our users, customers, or others; and in connection with
            our Terms of Use and other agreements.
          </li>
        </ul>
        <h2>EXTERNAL LINKS</h2>
        <p>
          For your convenience, We may provide links on the Platform operated by
          organizations other than Us ("Third Party Sites") that we believe may
          be of interest to you. We do not disclose your Data to these Third
          Party Sites unless we have a lawful basis on which to do so. We do not
          endorse and are not responsible for the privacy practices of these
          Third-Party Sites. If you choose to click on a link to one of these
          Third-Party Sites, you should review the privacy policy posted on the
          other Site to understand how that Third Party Site collects and uses
          your Personal Data.
        </p>
        <h2>RETENTION OF PERSONAL INFORMATION</h2>
        <p>
          We will keep your personal information for as long as it is required
          and according to the purpose it is being processed for, and/or as
          required to comply with applicable laws and establish, exercise, or
          defend our legal rights. We will keep the personal data for a period
          which enables us to handle or respond to any complaints, queries, or
          concerns relating to Our relationship with you.
        </p>
        <h2>DATA SECURITY AND CONFIDENTIALITY</h2>
        <p>
          We will use industry-standard physical, technical, and administrative
          security measures to keep your Data confidential and secure and will
          not share it with third parties, except as otherwise provided in this
          Privacy Notice, or unless such disclosure is necessary in exceptional
          cases, such as a physical threat to you or others, as permitted by
          applicable law.
        </p>
        <p>
          Please note that you also have a significant role in protecting your
          information. No one can see or edit your personal information without
          knowing your username and password, so do not share these with others.
          However, as the internet is not a secure medium, DSN AI Innovation
          Limited cannot guarantee that security breaches will never occur.
          Accordingly, we are not responsible for the matters, including actions
          of hackers and other unauthorized third parties that breach Our
          reasonable security procedure.
        </p>
        <h2>DISCLAIMER</h2>
        <p>
          By this Policy, We do not represent or warrant the condition or
          functionality of Our Platform (s), its suitability for use, nor
          guarantee that Our Service will be free from interruption or any
          error. No liability or responsibility shall lie for risks associated
          with the use of Our Platform, including but not limited to any risk to
          your computer, software, or data being damaged by any virus, software,
          or any other file that might be transmitted or activated via Our
          Platform or your access to it. Neither do We guarantee the reliability
          of the information contained on Our Platform—particularly those shared
          by third-party users.
        </p>
        <h2>YOUR LEGAL RIGHTS</h2>
        <p>You have the right to:</p>
        <ul>
          <li>
            request access to your data (commonly known as a "data subject
            access request"). This enables you to receive a copy of your
            personal information. We hold about you and check that We are
            lawfully processing it. Please note that a data subject access
            request may attract an administrative fee
          </li>
          <li>
            request correction of the personal data that We hold about you. This
            enables you to have any incomplete or inaccurate data We hold about
            you corrected, though We may need to verify the accuracy of the new
            data you provide to us;
          </li>
          <li>
            request erasure of your data. This enables you to ask us to delete
            or remove personal data where there is no good reason to continue
            processing it. You also have the right to ask us to delete or remove
            your data where you have successfully exercised your right to object
            to processing (see below), where We may have processed your
            information unlawfully or where We are required to erase your data
            to comply with local law. Note, however, that We may not always be
            able to comply with your request of erasure for specific legal
            reasons, which will be notified to you, if applicable, at the time
            of your request;
          </li>
          <li>
            object to processing your data where We are relying on a legitimate
            interest (or those of a third party). There is something about your
            particular situation that makes you want to object to processing on
            this ground as you feel it impacts your fundamental rights and
            freedoms. You also have the right to object where We are processing
            your data for direct marketing purposes. In some cases, We may
            demonstrate that We have compelling legitimate grounds to process
            your information which override your rights and freedoms;
          </li>
          <li>
            request the transfer of your data to you or a third party. We will
            provide you, or a third party you have chosen, your data in a
            structured, commonly used, machine-readable format. Note that this
            right only applies to automated information which you initially
            provided consent for us to use or where We used the information to
            perform a contract with you. Please note that this request may also
            attract an administrative fee;
          </li>
          <li>
            withdraw consent at any time where We are relying on consent to
            process your data. However, this will not affect the lawfulness of
            any processing carried out before you withdraw your consent. If you
            withdraw your consent, We may not be able to provide certain
            products or services to you. We will advise you if this is the case
            at the time you withdraw your consent.
          </li>
        </ul>
        <h2>CONTACT US</h2>
        <p>
          If You have any questions about this Privacy Notice, don't hesitate to
          get in touch with Us{" "}
          <strong>
            <a
              href="https://www.datasciencenigeria.org/contact-us/"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </strong>
        </p>
      </PrivacyWrapper>
      <ScrollTop />
    </>
  );
};

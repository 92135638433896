import styled from "styled-components";

export const Logo = styled.div`
  position: absolute;
  top: 0rem;
  left: 1rem;
  img {
    height: 8rem;
  }

  @media screen and (max-width: 500px) {
    img {
      height: 6rem;
    }
  }
`;

export const GeneralLogoWrapper = styled.div`
  img {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    height: 8rem;
    cursor: pointer;
  }

  @media screen and (max-width: 700px) {
    img {
      height: 6rem;
    }
  }
`;
